@import '../../mixins';

.arrows {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  .arrow-wrapper {
    overflow: hidden;
    height: 24px;
    width: 80px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
  .arrow {
    position: relative;
    width: 50px;
    height: 50px;
    top: 50%;
    transform: translateY(-50%);
    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-top: 30px solid transparent;
      border-bottom: 30px solid transparent;
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &.rotate180 {
    img {
      transform: translate3d(-50%, -50%, 0) rotate(180deg);
    }
  }
  &.rotate90 {
    img {
      transform: translate3d(-50%, -50%, 0) rotate(90deg);
    }
  }
  &.rotate270 {
    img {
      transform: translate3d(-50%, -50%, 0) rotate(270deg);
    }
  }
  &.row-reverse {
    flex-direction: row-reverse;
  }
  &.column-reverse {
    flex-direction: column-reverse;
  }
  &.column {
    flex-direction: column;
  }
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
  &.person-arrow {
    top: 197px;
    left: 300px;
    @include min-width(1367) {
      //top: 280px;
      //left: 350px;
    }
  }
  &.warm-water-arrow {
    top: 244px;
    left: 625px;
    @include min-width(1367) {
      //top: 388px;
      //left: 560px;
    }
    .arrow {
      background: #ff4b4b!important;
      &:before {
        border-left: 30px solid #ff4b4b!important;
      }
    }
    .percent-value {
      background: #ff9696!important;
      border: 1px solid #ce000c!important;
    }
  }
  &.roof-arrow {
    top: 27px;
    right: 194px;
    left:auto;
    @include min-width(1367) {
      //top: 120px
    }
  }
  &.wall-arrow {
    top: 145px;
    left: auto;
    right: 111px;
    @include min-width(1367) {
      //top: 427px
    }
  }
  &.floor-arrow {
    top: 411px;
    left: 625px;
    @include min-width(1367) {
      //top: 630px
    }
  }
  &.ventilation-arrow {
    top: 375px;
    left: 112px;
    @include min-width(1367) {
      //top: 650px;
    }
  }
  &.ventilation-systems-arrow {
    top: 290px;
    left: 260px;
    @include min-width(1367) {
      //top: 650px;
    }
    .arrow {
      background: #888888!important;
      &:before {
        border-left: 30px solid #888888!important;
      }
    }
    .percent-value {
      background: #888888!important;
      border: 1px solid #5f5f5f !important;
    }
  }
  &.living-needs-arrow {
    top: 336px;
    left: 390px; 
    @include min-width(1367) {
      //top: 650px;
    }
    .arrow {
      background: #888888!important;
      &:before {
        border-left: 30px solid #888888!important;
      }
    }
    .percent-value {
      background: #888888!important;
      border: 1px solid #5f5f5f !important;
    }
  }
  &.windows-arrow {
    top: 298px;
    left: 112px;
    @include min-width(1367) {
      //top: 500px;
      //left: 117px;
    }

  }
  &.airing-arrow {
    top: 224px;
    left: 112px;
    @include min-width(1367) {
      //top: 326px;
      //left: 92px;
    }
  }
  &.sun-arrow {
    top: 175px;
    left: 163px;
    @include min-width(1367) {
      //top: 400px;
      //left: 125px;
    }
  }
  &.heat-loss-arrow {
    top: 355px;
    left: auto;
    right: 111px;
    @include min-width(1367) {
      //top: 335px
    }
    &.gain {
      right: 161px;
      .arrow-wrapper {
        transform: translate3d(-50%, -50%, 0) rotate(180deg);
      }
    }
    &.loss {
      flex-direction: row-reverse;
    }
  }
  &.heating-systems-arrow {
    top: 208px;
    left: auto;
    right: 230px;
    @include min-width(1367) {
      //top: 335px
    }
    .arrow {
      display: none;
    }
    .percent-value {
      background: #888888!important;
      border: 1px solid #5f5f5f !important;
    }
  }
  &.collector-arrow {
    top: 67px;
    left: 535px;
    @include min-width(1367) {
      //top: 70px;
      //left: 610px;
    }
    .arrow {
      background: #ff9797!important;
      &:before {
        border-left: 30px solid #ff9797!important;
      }
    }
    .percent-value {
      background: #ff9797!important;
      border: 1px solid #ff9797!important;
    }
  }
  &.air-conditioning-arrow {
    top: 230px;
    left: auto;
    right: 111px;
    .arrow {
      background: #0088FE!important;
      &:before {
        border-left: 30px solid #0088FE!important;
      }
    }
    .percent-value {
      background: #0088FE!important;
      border: 1px solid #0062bd!important;
    }
  }
  &.basement-arrow {
    top: 305px;
    left: auto;
    right: 111px;
  }
  &.photovoltaic-arrow {
    top: 67px;
    left: 587px;
    @include min-width(1367) {
      //top: 70px;
      //left: 610px;
    }
    .arrow {
      background: #ffe400!important;
      &:before {
        border-left: 30px solid #ffe400!important;
      }
    }
    .percent-value {
      background: #ffe400!important;
      border: 1px solid #d4ba00!important;
    }
  }
  &.gain {
    color: #75ae2e;
    &.rotate180 {
      .arrow-wrapper {
        //transform:translate3d(-50%,-50%,0) rotate(180deg);
      }
    }
    &.rotate90 {
      .arrow-wrapper {
        transform: translate3d(-50%, -50%, 0) rotate(-90deg);
      }
    }
    &.rotate270 {
      .arrow-wrapper {
        transform: translate3d(-50%, -50%, 0) rotate(90deg);
      }
    }
    .arrow {
      background: #4b8305;
      &:before {
        border-left: 30px solid #4b8305;
      }
    }
    .percent-value {
      font-weight: 400;
      padding: 2px 4px;
      border: 1px solid #4b8305;
      line-height: 100%;
      vertical-align: middle;
      font-size: 10px;
      color:#000000;
      background: #75ae2e;

    }
  }
  &.loss {
    color: #ec6d11;
    &.rotate180 {
      .arrow-wrapper {
        transform: translate3d(-50%, -50%, 0) rotate(180deg);
      }
    }
    &.rotate90 {
      .arrow-wrapper {
        transform: translate3d(-50%, -50%, 0) rotate(90deg);
      }
    }
    &.rotate270 {
      .arrow-wrapper {
        transform: translate3d(-50%, -50%, 0) rotate(270deg);
      }
    }
    .arrow {
      background: #ec6d11;
      &:before {
        border-left: 30px solid #ec6d11;
      }
    }
    .percent-value {
      font-weight: 400;
      padding: 2px 4px;
      border: 1px solid #ec6d11;
      line-height: 100%;
      vertical-align: middle;
      font-size: 10px;
      color:#000000;
      background: #f9964f;
    }
  }
  .percent-value {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    color: #fff;
    font-weight: 700;
    padding: 0 2px;
  }
  .label-value {
    font-weight: 700;
    span {
      font-size: 10px;
      margin-top: -5px;
      display: block;
    }
  }
  .square {
    width: 90px;
    height: 90px;
  }
  > div:not(.square) {
    background: rgba(255, 255, 255, 0.75);
    padding: 12px 7px 10px;
    border-radius: 100%;
    line-height: 110%;
    height: 50px;
    width: 50px;
    vertical-align: middle;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

