@import '../../mixins';

.baloon {
  position: absolute;
  top: 90px;
  left: 200px;
  z-index: 2;
  width:187px;
  .react-numeric-input, .react-numeric-input > input{
    min-width:0;
  }
  &.popover {
    padding:0;
  }
  &.hidden {
    background: none;
    border:none!important;
    .arrow {
      opacity:0;
      visibility:hidden;
    }
    .popover-body {
      opacity:0;
      visibility:hidden;
    }
  }
  &.window-baloon {
    top: 301px;
    left: -120px;
    @include min-width(1367){
      //top:480px;
      //left: -122px;
    }
    @include max-width(1365){
      left:-10px;
    }
  }
  &.ventilation-baloon {
    top: 389px;
    left: -120px;
    @include min-width(1367){
      //top:607px;
    }
    @include max-width(1365){
      left:-10px;
    }
  }
  &.insulation-baloon {
    left: auto;
    .card-body .row:not(.thermoderm) {
      .col-12 {
        max-width: 84px;
        padding-right: 0px;
      }
      .u0 {
        max-width: 100%;
        padding-right: 15px;
      }
      .col-6.pr-1 {
        max-width: 53px;
        padding-left: 5px;
        padding-top: 9px;
        padding-right: 0px;
      }
      .col-6.pl-1 {
        max-width: 65px;
        padding-left: 0px!important; padding-top:9px;
      }
    }

  }
  &.basement-baloon {
    top: 10px;
    right: -100px;
  }
  &.insulation-baloon-roof {
    top: 10px;
    right: -120px;
    @include min-width(1367){
      //top:180px
    }
   
  }
  &.insulation-baloon-wall {
    right: -120px;
    top: 120px;
    @include min-width(1367){
      //top:477px
    }
    @include max-width(1365) {
      right:3px;
    }
  }
  &.insulation-baloon-floor {
    right: -120px;
    top: 389px;
    @include min-width(1367){
      //top:620px
    }
    @include max-width(1365) {
      right:3px;
    }
    .u0 {
    }
  }
  &.dropdown-baloon-airing {
    top: 241px;
    left: -120px;
    @include min-width(1367){
      //top:320px;
      //left: -80px;
    }
  }
  &.dropdown-baloon-water {
    top: 247px;
    left: 512px;
    width:auto;
    @include min-width(1367){
      //top:264px;
      //left:520px;
    }
  }
  &.dropdown-baloon-air-conditioning {
    top: 247px;
    left: auto;
    right: -100px;
  }
  &.personal-gain-baloon {
    left: 365px;
    top: 247px;
    width: 138px;
    @include min-width(1367){
      //top:460px;
      //left:310px;
    }
    .custom-icon {
      margin-left:-5px;
      margin-right:0;
    }
    .card-body {
      padding-bottom:13px;
    }
    .content p {
       padding-bottom:0;
    }
  }
  &.insolation-baloon {
    bottom: 700px;
    top:auto;
    left: -120px;
    @include min-width(1367){
      //top:415px;
      //left: -80px;
    }
    @include max-width(1365){
      left:-10px;
    }
  }
  &.source-loss-baloon {
    top: 321px;
    right: -120px;
    left: auto;
    @include min-width(1367){
      //top:277px;
      right: -140px;
    }
    @include max-width(1365) {
      right:3px;
    }
  }
  &.heat_electric_system-baloon {
    top: 375px;
    right: -120px;
    left: auto;
    @include min-width(1367){
      //top:277px;
      right: -140px;
    }
    @include max-width(1365) {
      right:3px;
    }
  }
  &.ventilation_electric_system-baloon {
    top: 302px;
    left: -120px;
    @include min-width(1367){
      //top:277px;
      //right: -140px;
    }
    @include max-width(1365) {
      left: -10px;
    }
  }
  &.photovoltaic-baloon {
    left:500px;
  }
  &.living_needs-baloon {
    top:480px;
    left: 345px;
  }
  .content {
    position: relative;

    p {
      margin: 0px;
      line-height: 100%; padding-bottom: 3px;
    }
    img {
      position: absolute;
      top: 5px;
      right: 10px;
      cursor: pointer;
      &.as-svg {
        position: static;
      }
    }

  }
  .baloon-icon {
    position: absolute;
    cursor: pointer;
    padding: 5px;
    background: #fff;
    border-radius: 25px;
    width: 32px;
    height: 32px;
  }
  &.bs-popover-top-right {
    .baloon-icon {
      bottom:0px
    }
    .arrow {
      bottom:10px;
    }
  }
  &.bs-popover-left {
    .baloon-icon {
      right:-40px;
    }
    .arrow {
      left:100%;
    }
  }
  &.bs-popover-right {
    .baloon-icon {
      left:-40px;
    }
    .arrow {
      right:100%;
    }
  }
  &.bs-popover-top {
    .baloon-icon {
      bottom:-40px;
      left: 50%;
      transform: translateX(-50%);
    }
    .arrow {
      left:50%;
      transform:translateX(-50%);
      top:100%;
    }
  }
  &.bs-popover-bottom {
    .baloon-icon {
      top:-40px;
      left: 50%;
      transform: translateX(-50%);
    }
    .arrow {
      left:50%;
      transform:translateX(-50%);
      bottom:100%;
    }
  }
  &.warning {
    .baloon-icon {
      border: 2px solid red !important;
      padding:3px;
    }
  }
}
.popover {
  padding:0.75rem 0.75rem 0;
  .description {
    p {
      line-height: 1.2em;
    }
  }
}
.popover-body {
  padding: .4rem .5rem;
  h3 {
    font-size:1.2rem;
    line-height:100%;
  }
    p {
      font-size: 0.75rem;
    }
  .description {

    p {
      font-size: 0.9rem;
    }
  }
  .fa-info-circle{
    position: absolute;
    right:5px;
    top:5px;
    cursor:pointer;
    & {
      color:#cc0000;
    }
  }
}
.short-input {
  max-width:60px;
}
.content {
  .doors, .foundations {
    p {
      margin-bottom:0;
    }
  }
}

.insulation-input {
  width:56px;
}
.small-input {
  max-width:56px;
}

.medium-input {
  max-width:70px;
}

.persons {
  display:inline-block;
  max-width: 47px;
  overflow: hidden;
  height: 19px;
}
.person-icon {
  margin-right:-5px;
}
.no-accordion-styles {
  .card {
    border-radius: 0.3rem!important;
    overflow:visible;
    flex-direction: inherit;
    &.baloon {
      border-bottom:1px solid rgba(0, 0, 0, 0.2);
      &.hidden {
        border-bottom:none;
      }
    }

  }
  .card-header {
    padding:0;
    background:none;
    border:none;
    margin-bottom: .3rem!important
  }
  .card-body {
    padding:0;
  }
  .icon-caret-toggle {
    display:none;
  }
}
.residual-needs {
  line-height:100%;
}

.hidePanels {
  .baloon {
    .baloon-icon {
      display: none;
    }
    &.window-baloon {
      left: -100px;
    }
    &.ventilation-baloon {
      left: -100px;
    }
    &.ventilation_electric_system-baloon {
      left: -100px;
    }
    &.insulation-baloon-roof {
      right: -100px;
    }
    &.insulation-baloon-wall {
      right: -100px;
    }
    &.insulation-baloon-floor {
      right: -100px;
    }
    &.dropdown-baloon-airing {
      left: -100px;
    }
    &.dropdown-baloon-water {
      left: 512px;
    }
    &.personal-gain-baloon {
      left: 365px;
    }
    &.insolation-baloon {
      left: -100px;
    }
    &.source-loss-baloon {
      right: -100px;
    }
    &.heat_electric_system-baloon {
      right: -100px;
    }
  }
}

.photovoltaic-icon {
  top: 130px;
  left: 650px;
  position: absolute;
}

.collectors-icon {
  top: 130px;
  left: 530px;
  position: absolute;
}

.insulation-baloon-floor.baloon.bs-popover-right .baloon-icon {left: -325px;}
.insulation-baloon-wall.baloon.bs-popover-right .baloon-icon {left: -45px; top: 118px;}
.insulation-baloon-roof.baloon.bs-popover-left .baloon-icon {left: -148px; top: -34px; }
.insolation-baloon.baloon.bs-popover-left {
  .baloon-icon {
    right: -103px;
    top: auto;
    bottom:0;
  }
  .arrow {top:70%;}
}
  .window-baloon.baloon.bs-popover-left .baloon-icon {right: -45px; }
  .ventilation-baloon.baloon.bs-popover-left .baloon-icon {right: -45px; top: 16px;}
  .dropdown-baloon-airing.baloon.bs-popover-left .baloon-icon {right: -45px;}
  .personal-gain-baloon.baloon.bs-popover-bottom .baloon-icon {left: 20%;}
  .baloon.source-loss-baloon .baloon-icon {left: -45px;}
  .dropdown-baloon-water.baloon.bs-popover-top .baloon-icon {left:93%}

#airTightnessDropDownList, #ventilationDropDown, #ventilationPatencyDropDown {
  min-width:0;
}
.patency-info {
  color:#000!important;
  position: static!important;
}

