/***********************************/
/***********do wszystkiego**********/
/***********************************/

body {background-color: #7d6b4f; font-size: 0.9rem; font-family:Roboto, Arial, Tahoma, sans-serif;}

input.short-input {color: #000000; font-size: 0.87rem!important;}

.arrows .percent-value, .arrows.loss .percent-value, .arrows.gain .percent-value {font-size: 12px; vertical-align:middle;}

.row, .thermoderm.row {margin-bottom: 0px;margin-top: 0px;}
.thermoderm.row {margin-bottom: 0px!important;}

.warning-alert {
    margin: 0 0 10px;
    line-height: 115%;
    padding-top: 5px;
    padding-bottom: 5px;
}
.welcome.modal .modal-content .modal-body {padding: 2rem;}
.welcome.modal .modal-content .modal-body p.release {top: 17px; left: 13px; font-size: 0.75rem;}
.navbar-brand, img.logo { max-width: 196px; margin: 0 auto 20px; padding-top:0px;}

.navbar-header img.logo {max-width: 120px; margin-left: 0px; left: 15px; padding-top:0px;}

img.logo.logoue {margin-right: -12px; margin-top: -32px; margin-bottom: 20px;}

.welcome.modal .modal-content .partner-logo {margin-bottom:20px;}
.welcome.modal .modal-content .partner-logo#logo-wfos { margin-left: -6px;}
.modal-content .modal-footer .logotypy {width: 101%!important;}

#logo-fer {max-height: 40px;}

.modal-content .modal-footer>:not(:first-child) {margin-left: 1.1rem}

.energy-meter .btn.rounded-0 {padding-left: 10px!important;}
.energy-meter .btn .px-1 > p.mb-0 {font-size: 0.87rem; text-align:left; padding-top: 7px; line-height: 100%;}
.energy-meter .btn .px-1 .justify-content-center {justify-content: left!important;}

#content_container {background-color: #7d6b4f!important;}
.hidePanels .baloon.insolation-baloon .popover-body > div.content .card-body .row p.col-12 {display:none!important;}
.hidePanels .baloon.insolation-baloon.bs-popover-left .arrow {bottom: 15px; top: auto;}

.hidePanels .baloon.ventilation-baloon.baloon.bs-popover-left .arrow {top: 64px;}

.heating-device#combined_electric_heat, .heating-device#combined_heat {width:auto;}

.baloon .content p {line-height: 110%; padding-bottom: 1px;}
.popover-body {padding: 0.25rem 0.5rem;}

.insulation-baloon .card-body .row .col-12 {max-width:85px; padding-right:0px;}
.insulation-baloon .card-body .row .col-6.pr-1 {max-width:46px; padding-left:5px; padding-top:9px; padding-right:0px;}
.insulation-baloon .card-body .row .col-6.pl-1 {max-width:63px;
	padding-left: 0px!important; padding-top:9px; }

.dropdown-baloon-water .content .card-body {padding-top:7px; padding-bottom:2px;}
.dropdown-baloon-water .content .card-body p {padding-bottom:3px;}

/*nowe*/
#content_container.hidePanels .heating-popup, #content_container.hidePanels .house-popup, #content_container.hidePanels .photovoltaic-popup {
	border-radius: 5px;
	display: block;
	z-index: 1070;
	border: 1px #c8c8c8 solid;
	border-bottom: 1px #c8c8c8 solid!important;
	box-shadow: 0px 0px 5px rgba(0,0,0,0.1);}

#content_container #PhotovoltaicPopup2 .heatPopupMainGroup div:nth-child(2) div p.lh-100 {max-width: 155px!important;}

#content_container #PhotovoltaicPopup2 .heatPopupMainGroup h5 {font-size: 18px;}

#content_container #PhotovoltaicPopup1 .heatPopupMainGroup .col-4 p, #content_container #PhotovoltaicPopup2 .heatPopupMainGroup .col-4 p {padding-top: 7px!important; line-height: 100%; vertical-align:top;}

/*inne*/

.housePopupUpperGroup .house-numeric-input p.text-small {line-height: 110%; padding-bottom: 2px;}

.house-select img.house-icon {margin-top:-5px;}

.select-appearance {-webkit-appearance: menulist-button;border: 0px #fff solid; width: 84%;
    height: 88%;
    right: 16px;
    top: 1px;}

.walls .btn-secondary {margin-top:5px;}
.house-popup .card-body h3.mb-0, .heating-popup .card-body h3.col-12, .heating-popup .card-body h4, #content_container #PhotovoltaicPopup2 .heatPopupMainGroup h5:nth-child(3), #content_container #PhotovoltaicPopup2 .heatPopupMainGroup div:nth-child(8) h5 {
	font-size: 18px;
	margin-top: 4px;
	border-top: 1px solid rgba(0,0,0,.05);
	padding-top: 7px;
	padding-bottom: 5px;
}
#content_container #PhotovoltaicPopup2 .heatPopupMainGroup div:nth-child(8) h5 {
margin-right: -15px;
margin-left: -15px; padding-left: 15px; padding-right: 15px; margin-top: 7px;}

.heating-popup .card-body h4 {border-top: 0;}
.heating-popup .maxPowerRichText {font-weight: 700;}
.heating-popup .card-body .align-items-center .heat, .heating-popup .card-body .align-items-center .water, .heating-popup .card-body .align-items-center .residual-needs {font-size: .75rem; max-width: 35%;}
.heating-popup .card-body .align-items-center .water{padding-left: 20px;}

.heating-input, .small-input, .short-input {max-width: 70px;}
.walls .wall-row .short-input {max-width: 60px;}

.house-numeric-input.pl-2 {padding-left: 0.1rem!important;}
.house-numeric-input .text-small.ml-2 {margin-left: 0.2rem!important;}

	.ventilation-baloon .form-control, .dropdown-baloon-airing .form-control {min-width: 165px;}

.custom-accordion .card.photovoltaic-popup {border-top: 1px solid rgba(0,0,0,.2);}

.personal-gain-baloon .content p {position:relative; top: -17px; padding-bottom: 13px;}
.personal-gain-baloon .content .card-body .align-items-center {position:relative; top:20px;}

.card-body .thermoderm.row .col-12 {max-width:100%!important; width: 100%}

.popup.house-popup#HousePopup2.firstCalculationStep1 .card-body {min-height: 365px}
#HeatingPopup2 .card-body, #HousePopup2 .card-body {min-height: 295px;}

#flatCollector1, #flatCollector2, #flatCollector3, #flatCollector4, #vacuumCollector1, #vacuumCollector2 {
    top: 70px;
    left: 417px;
width: 170px;}

/*img#pompa-ciepla-wiatraczki, img#heating-device-air_air {right: 1px;}*/
#wallRightInsulationImg {top:210px;}

#mechanicalImg {bottom: 239px;}

/*optymalizator*/


.optimizer-container-report div:first-child > div > div:not(.thermoderm-optimize) > svg {right:20px;}
.optimizer-container-report .optimizer-close {font-size: 30px;}


/*.optimizer-container-report .changer-wrapper > .change-row:first-child p {
	font-size: 16px;
    margin-top: 0px;
	margin-bottom: 0px;
    border-top: 0px solid rgba(0,0,0,.05);
    padding-top: 0px;
    padding-bottom: 0px;
	font-family: Roboto Condensed,sans-serif;
	text-align: left!important;
	line-height:105%;}*/

.optimizer-container-report .changer-wrapper .change-row .row-header p {font-weight: 400!important;line-height: 120%; text-align: left!important; margin-bottom: 0.25rem!important}
.optimizer-container-report .changer-wrapper .change-row .row-header p.col-6, .optimizer-container-report .changer-wrapper .config-header p, .optimizer-container-report .changer-wrapper .investments-cost > p, .optimizer-container-report .changer-wrapper .savings-header p {font-family:Roboto Condensed, sans-serif; font-size: 16px; margin-bottom: 0rem;}

.optimizer-container-report .changer-wrapper .change-row-header .change-details .config-header .col-6  > p {font-family: Roboto, sans-serif; font-size: 0.9rem;}

.optimizer-container-report .changer-wrapper .change-row-header .col-8 .investments-cost {background:#ffff00;  border-left: 1px solid rgba(0,0,0,.2);}
.optimizer-container-report .changer-wrapper .change-row-header .change-details .row .col-6:first-child .col-6:nth-child(2) {background-color: transparent}

.optimizer-container-report .changer-wrapper .savings {background:rgba(0,255,34,.21);  border-left: 1px solid rgba(0,0,0,.2);}

.optimizer-container-report .changer-wrapper .savings-rates {background:rgba(0,132,255,.21);  border-left: 1px solid rgba(0,0,0,.2);}

.optimizer-container-report .changer-wrapper .change-row .change-details .row .col-6.cost-details {background-color:rgba(255,255,0,0.35); padding: 0px!important; border-left:1px solid rgba(0,0,0,.2)}

.changer-wrapper .change-row .change-details .row+.row {border: 0px; padding: 0px!important;}
.optimizer-container-report .changer-wrapper .change-row:not(.change-row-header) .change-details .row .col-6:nth-child(2n+1) {padding-top:0.25rem!important; padding-bottom:0.25rem!important;}

/*.optimizer-container-report .changer-wrapper .change-row:first-child .change-details .row .col-6:nth-child(2) , .optimizer-container-report .changer-wrapper .change-row .change-details .row:nth-child(2) .col-6:nth-child(2) {max-width:300px;}*/

.optimizer-container-report .changer-wrapper .change-row-header .col-8 > .row, .optimizer-container-report .changer-wrapper .change-row:first-child .col-4 > .row {padding:0!important;}

.optimizer-container-report .changer-wrapper .change-row .change-details {flex: 0 0 67%; max-width: 67%; }
.optimizer-container-report .changer-wrapper .change-row .savings-details, .optimizer-container-report .changer-wrapper .change-row:first-child .col-4 {flex: 0 0 33%; max-width: 33%;}

.optimizer-container-report .changer-wrapper .change-row .change-details .row .col-6:nth-child(2n+1), .optimizer-container-report .changer-wrapper .change-row:first-child .change-details .row .col-6 .col-6:nth-child(2) {flex: 0 0 55%; max-width: 55%;}
.optimizer-container-report .changer-wrapper .change-row .change-details .row .col-6:nth-child(2n), .optimizer-container-report .changer-wrapper .change-row:first-child .change-details .row .col-6 .col-6:nth-child(3) {flex: 0 0 45%; max-width: 45%;}

.optimizer-container-report .changer-wrapper .change-row-header .change-details .row .col-6 .col-6:nth-child(2), .optimizer-container-report .changer-wrapper .change-row:first-child .change-details .row .col-6:first-child .col-12:first-child {padding-left:0;}

.optimizer-container-report .changer-wrapper .change-row-header .change-details .row .col-6:first-child p {text-align: left!important;}

.optimizer-container-report .irr-form p {line-height:100%;}

.optimizer-container-report .irr-form .house-numeric-input .react-numeric-input #irr {min-width: 75px; text-align:right; max-width: 75px;}

.changer-wrapper .change-row .savings-details {padding-top: 2px; padding-bottom:4px;}

.thermoderm {padding-bottom: 10px;}
.card-body .thermoderm.row .col-6 p {line-height: 110%; margin-bottom: 0;}

.results-summary p {line-height: 135%}


	.optimizer-container-report .change-details {max-width: 75%; flex: 0 0 75%;}
	.optimizer-container-report .savings-details {max-width: 25%;}
	.optimizer-container-report .house-popup-static .card-body {padding-bottom: 0px;}
	.optimizer-container-report .change-details p.mb-0, .optimizer-container-report .cost-details p.mb-0 {margin-bottom: 0.25rem!important; line-height: 100%;}
	/*.optimizer-container-report .change-details div.obsolescence-details::after {content: " lat";}*/

.thermoderm-optimize {text-align: right;}
.thermoderm-optimize .btn {margin-right: 5px; right: 0px; margin-bottom: 5px; margin-top:3px; font-size: 0.86rem; padding: 0.05rem 0.7rem;}


/*****wykresy******/
.energy-cost {
    font-size: 1.3rem;
    font-weight: bold;
    top: 0px;
	text-align:left!important;
	}
.energy-cost h4 {font-size: 1rem; font-family: Roboto, Arial, Tahoma, sans-serif; color:#333c45; margin-bottom: 2px; font-weight: 400;}

.energy-class-value {text-align:left; margin-right: 40px; font-size: 1.3rem;
    line-height: 100%; padding-right: 20px; padding-top: 6px; text-transform: uppercase; font-weight: bold;}

#plotContainer > .d-flex > .col-7 > .row > .col-5.p-0, #plotContainer > .d-flex > .col-7 > .row > .col-8.p-0 {max-width: 200px; flex: 0 0 28.66667%;}
#plotContainer > .d-flex > .col-7 > .row > .col-3:nth-child(3) {max-width: 130px;    font-weight: bold; padding-right: 40px!important; padding-top: 2px;}
#plotContainer > .d-flex > .col-7 > .row > .col-3:nth-child(4) .text-left {width:50px;}
#plotContainer > .d-flex > .col-7 > .row > .col-3:nth-child(4) .text-right {width:75px; font-weight:bold; padding-top: 2px!important;}

/*charakterystyka-budynku*/

.house-specs {max-width:800px; margin: 0 auto;}
.house-specs table {border: 0px;}
.house-specs table td {border: 1px #dedede solid; border-collapse: collapse;}
.house-specs table th {border: 0px;}
.house-specs h2 {font-size: 18px; margin-bottom: 10px!important;color: #333c45;}
.house-specs table td:first-child {text-align:left;}
.house-specs table td .energy-class-value {text-align: center; padding-right: 0px; margin: 0px;}
.house-specs table td:nth-child(2), .house-specs table td:nth-child(3), .house-specs table td:nth-child(4), .house-specs table td:nth-child(5) {width: 140px;}

/*wyostrzanie czcionek i obrazków*/

.house-types .house-type.col-6 {font-size: 12px; max-width: 48%;}
.house-types .house-type.col-4 {font-size: 12px; width: 33%; max-width: 164px; flex: 0 0 33%;}
.house-types .house-type span img {max-width:100px;}
.modal-dialog {max-width: 492px;}
.house-select img.house-icon {width: 22px; margin-top: -2px;}
#wallHoleRecInImg, #wallHoleImg {height:26px!important;}


/***********************************/
/****do dużych ekranów desktopa*****/
/***********************************/

@media only screen and (min-width:1650px) {

	.no-accordion-styles .baloon, .hidePanels .baloon.source-loss-baloon {width:240px;}
	.no-accordion-styles .baloon.personal-gain-baloon, .no-accordion-styles .baloon.dropdown-baloon-water {width: 130px;}
	.no-accordion-styles .baloon .popover-body {width:100%;}
	.foundations .form-control {min-width: 164px;}
	.doors .form-control {min-width: 164px;}

	/*panele lewe*/

	.custom-accordion .popup .custom-icon {margin-left:-3px;}
	.custom-accordion .card.photovoltaic-popup {border-top: 1px solid rgba(0,0,0,.2);}

	.custom-accordion .baloon .custom-icon {font-size: 24px; height: 24px; min-width: 24px; margin-right: .7rem!important;}
	.custom-accordion .baloon.personal-gain-baloon .custom-icon {margin-left: 0px;}
	.custom-accordion .card-body {padding: 1rem 1.25rem .75rem; min-height: 75px;}

	.accordion .popup .card-header .custom-icon {font-size: 30px; height: 30px; min-width: 30px; margin-right: .7rem!important;}

	/*ikonki*/

	.insolation-baloon.baloon.bs-popover-left .baloon-icon {right: 162px; top: auto; bottom: -125px;}
	.dropdown-baloon-airing.baloon.bs-popover-left .baloon-icon {right: 162px; top: 166px;}
	.window-baloon.baloon.bs-popover-left .baloon-icon {right: 162px; top:190px;}
	.ventilation-baloon.baloon.bs-popover-left .baloon-icon {right: 162px; top: 273px;}

	.collectors-icon.baloon .baloon-icon {right: 355px; top: 55px;}
	.photovoltaic-icon.baloon .baloon-icon {right:130px; top: 55px;}
	.personal-gain-baloon.baloon .baloon-icon {left: -40px; top: 153px;}
	.dropdown-baloon-water.baloon .baloon-icon {right: -17px; top: 197px;}

	.insulation-baloon-roof.baloon .baloon-icon {left: 44px; top: 175px; }
	.insulation-baloon-wall.baloon.bs-popover-right .baloon-icon {left: 162px; top: 184px;}
	.baloon.dropdown-baloon-air-conditioning .baloon-icon {top: 186px; left:182px;}
	.baloon.source-loss-baloon .baloon-icon {left: 82px; top:198px;} /*nietrzebazmieniac left*/
	.insulation-baloon-floor.baloon.bs-popover-right .baloon-icon {left: -547px; top: 321px;}
	.basement-baloon.baloon .baloon-icon {left: -685px; top: 700px;}

	/*dymki*/


	.hidePanels .baloon.insolation-baloon, .hidePanels .baloon.dropdown-baloon-airing, .hidePanels .baloon.window-baloon, .hidePanels .baloon.ventilation-baloon {left:-290px;}

	.photovoltaic-popup .heatPopupMainGroup div.col-4:first-child > p, .photovoltaic-popup .heatPopupMainGroup div.col-4:nth-child(2) > p  {padding-top:5px;}

	.hidePanels .baloon.insolation-baloon {top:auto; bottom: 565px;}
	.hidePanels .baloon.dropdown-baloon-airing {top:393px;}
	.hidePanels .baloon.window-baloon {top:462px;}
	.hidePanels .baloon.ventilation-baloon {top:620px;}
	.hidePanels .baloon.ventilation-baloon.baloon.bs-popover-left .arrow {top: auto;}


	.hidePanels .baloon.personal-gain-baloon {left: 338px;top: 375px;}
	.hidePanels .baloon.dropdown-baloon-water {   left: 523px;  top: 425px;}

	.hidePanels .baloon.insulation-baloon-roof, .hidePanels .baloon.insulation-baloon-wall, .hidePanels .baloon.dropdown-baloon-air-conditioning, .hidePanels .baloon.source-loss-baloon {right:-290px;}

	.hidePanels .baloon.insulation-baloon-roof {top:156px}
	.hidePanels .baloon.insulation-baloon-wall {top: 267px;}
	.baloon.insulation-baloon-wall.baloon.bs-popover-top-right .arrow {bottom: 43%;}
	.hidePanels .baloon.dropdown-baloon-air-conditioning {top: 410px;}
	.hidePanels .baloon.source-loss-baloon {top:515px;}
	.hidePanels .baloon.baloon.basement-baloon {top:642px; right: 488px;}
	.hidePanels .baloon.insulation-baloon-floor {top: 676px;right: 417px;}

	/*strzalki*/

	.arrows > div > .label-value {width: 100%;}

	.arrows.sun-arrow.gain {top:306px; left:15px;}
	.arrows.airing-arrow {top:378px; left:-36px;}
	.arrows.windows-arrow {top:460px; left:-36px;}
	.arrows.ventilation-arrow {top:633px; left:-36px;}

	.arrows.person-arrow.gain {top: 320px; left:260px;}
	.arrows.warm-water-arrow.loss {top: 415px; left: 643px;}
	.arrows.collector-arrow {top: 155px; left: 330px;}
	.arrows.photovoltaic-arrow {top: 154px; left: 595px;}

	.arrows.roof-arrow {top: 102px; right: 54px;}
	.arrows.wall-arrow {top: 275px;right:-36px;}
	.arrows.air-conditioning-arrow {top: 404px;left: auto;right: -36px;}
	.arrows.heat-loss-arrow.gain {top: 490px;right:15px;}
	.arrows.heat-loss-arrow.loss {top: 490px;right:-36px;}
	.arrows.floor-arrow {top: 682px; left: 273px;}
	.arrows.basement-arrow {top: 682px; left: auto; right: 658px;}

	/*inne*/
	#content_container {background-size:auto 985px!important;}

	.baloon.insulation-baloon .card-body .row:not(.thermoderm) .col-12 {max-width:143px;}
	.custom-accordion .baloon.insulation-baloon .card-body .row:not(.thermoderm) .col-12 {max-width:190px;}

	#shuttersWithoutBridgeImg {
		/*left: 43px!important;*/
		top: 293px!important;
		width: 80px!important;
		height: auto!important;
	}

	#bridgeDownInsulationImg {
		top: 554px;
	}
	/*.heating-device {right: 95px;}*/
	.heating-device#heat_pump-air_water-electric {right: 11px;}
	.heating-device#combined_electric_heat, .heating-device#combined_heat, .heating-device#heat_pump { bottom: 230px; right: 96px;}

	img#heat_pump-air_air-electric {top:340px;}
	img#heating-device-air_air {top:385px;}

	#wallDownLeftInsulationImg {top: 565px;}

	#roofGroup {top: 53px} /*taka niech będzie tutaj wartość startowa - bo wcześniej była dziura i prześwitywało niebo pomiędzy dachem a izolacją lub domem*/


	#recuperatorImg {bottom: 220px;}

}

/***********************************/
/*do laptopowych i trochę większych*/
/***********************************/

@media (min-width:1280px) and (max-width:1649px) {

	/*panele lewe*/

	.custom-accordion .popup .custom-icon {margin-left:-3px;}
	.custom-accordion .card.photovoltaic-popup {border-top: 1px solid rgba(0,0,0,.2);}

	.custom-accordion .custom-icon {font-size: 20px; height: 20px; min-width: 20px; margin-right: .7rem!important;}
	.custom-accordion .baloon.personal-gain-baloon .custom-icon {margin-left: 0px;}
	.custom-accordion .card-body {padding: 1rem 1.25rem .75rem; min-height: 75px;}

	.accordion .popup .card-header .custom-icon {font-size: 24px; height: 24px; min-width: 24px; margin-right: .7rem!important;}

	/*ikonki*/

	.insolation-baloon.baloon.bs-popover-left .baloon-icon {bottom: -45px; top: auto;}
	.dropdown-baloon-airing.baloon.bs-popover-left .baloon-icon {top:60px;}
	.window-baloon.baloon.bs-popover-left .baloon-icon {top:53px;}
	.ventilation-baloon.baloon.bs-popover-left .baloon-icon {top: 62px;}

	.collectors-icon.baloon .baloon-icon {top: -10px; left: -185px;}
	.photovoltaic-icon.baloon .baloon-icon { top: -10px;left: 15px;}
	.personal-gain-baloon.baloon .baloon-icon {left: -5px; top: 30px;}
	.dropdown-baloon-water.baloon .baloon-icon {right: -5px; top: 30px;}

	.insulation-baloon-roof.baloon.bs-popover-right .baloon-icon {left: -158px;top: 112px;}
	.insulation-baloon-wall.baloon.bs-popover-right .baloon-icon {left: -45px;top: 74px;}
	.dropdown-baloon-air-conditioning.baloon.bs-popover-right .baloon-icon {left: -25px;}

	.insulation-baloon-floor.baloon.bs-popover-right .baloon-icon {left: -650px;top: 68px;}
	.basement-baloon.baloon .baloon-icon {left: -630px; top: 447px;}

	/*strzalki*/

	.arrows.sun-arrow {top:225px}
	.arrows.airing-arrow {top:272px;}
	.arrows.windows-arrow {top:325px}
	.arrows.ventilation-arrow {top:422px}

	.arrows.roof-arrow {top:37px; left:70%;}
	.arrows.wall-arrow {top: 146px;}
	.arrows.air-conditioning-arrow {top: 238px;}
	.arrows.collector-arrow {left:356px}
	.arrows.photovoltaic-arrow {left:595px}

	.arrows.basement-arrow {top: 411px;left: auto;right: 610px;}

	/*dymki i ich elementy*/

	.hidePanels .baloon.insolation-baloon .popover-body > div.content .card-body .row p.col-12 {display:none!important;}
	.hidePanels .baloon.insolation-baloon {bottom:645px;top:auto;}
	.hidePanels .baloon.insolation-baloon .popover-body {padding-bottom:0px;}
	.hidePanels .baloon.insolation-baloon.bs-popover-left .arrow {}
	.hidePanels .baloon.dropdown-baloon-airing {top:295px;}
	.hidePanels .baloon.airing-baloon .popover-body {padding-bottom:1px;}
	.hidePanels .baloon.airing-baloon .arrow {top:14px;}
	.hidePanels .baloon.window-baloon {top:348px}
	.hidePanels .baloon.window-baloon .popover-body {padding-bottom:1px;}
	.hidePanels .baloon.window-baloon .arrow {top:15px}
	.hidePanels .baloon.ventilation-baloon {top:425px;}
	.hidePanels .baloon.ventilation-baloon .popover-body {padding-bottom:0px; margin-bottom:-5px;}
	.hidePanels .baloon.ventilation-baloon.baloon.bs-popover-left .arrow {top:35px;}

	.hidePanels .baloon.personal-gain-baloon {top:255px;}
	.hidePanels .baloon.dropdown-baloon-water { left: 512px;  top: 255px;  }


	.hidePanels .baloon.insulation-baloon-roof {top:59px;}
	.hidePanels .baloon.insulation-baloon-wall {top: 140px;}
	.hidePanels .baloon.insulation-baloon-wall .arrow {bottom:auto}
	.hidePanels .baloon.dropdown-baloon-air-conditioning {top: 249px;}
	.hidePanels .baloon.source-loss-baloon {top: 328px;}
	.hidePanels .baloon.source-loss-baloon .popover-body h3.col-10 {flex: 0 0 87.33333%; max-width:87.33333%}
	.hidePanels .baloon.insulation-baloon-floor {top:421px;}
	.hidePanels .baloon.baloon.basement-baloon {top: 391px; right: 440px;}

	/*inne*/
	#content_container {background-size:auto 666px!important;}

	#shuttersWithoutBridgeImg {
		/*left: 43px!important;*/
		top: 293px!important;
		height: auto!important;
		width: 85px!important;
	}

	#bridgeDownInsulationImg {
		top: 554px;
	}
	#recuperatorImg {bottom: 219px;}
	img#heat_pump-air_air-electric {top:310px;}
	.heating-device#heat_pump-air_water-electric {right: 11px;}
	img#heating-device-air_air {top:350px;}
	#floorInsulationImg {width: 915px!important;}


	/*poprawki bo sie wszystko rozsypalo 21.09.2019*/


}

/***********************************/
/****do tabletów i trochę większych*/
/***********************************/

@media (min-width:1280px) and (max-width:1365px) {

	.custom-accordion .row {margin-bottom:5px; margin-top:5px;}


	.housePanel-wrapper {
		width: calc(100% - 310px);
		margin: 0 0 0 auto;
		max-width: 100%;
	}

	.housePanel-container {
		width: 1000px;
		max-width: 100%;
		margin: 0 auto;
	}

}

/***********************************/
/****do tabletów i trochę większych*/
/***********************************/

@media (min-width:1024px) and (max-width:1279px) {

	.custom-accordion .row {margin-bottom:5px; margin-top:5px;}

	p.showPopups.mb-0.mx-1.d-flex {display: none !important;}

	.energy-meter {left:46%}

	.housePanel-wrapper {
		width: calc(100% - 310px);
		margin: 0 0 0 233px;
		max-width: 100%;
	}

	.housePanel-container {
		width: 1000px;
		max-width: 100%;
		margin: 0 auto;
	}

	/*panele lewe*/

	.custom-accordion .popup .custom-icon {margin-left:-3px;}
	.custom-accordion .card.photovoltaic-popup {border-top: 1px solid rgba(0,0,0,.2);}

	.custom-accordion .baloon .custom-icon {font-size: 24px; height: 24px; min-width: 24px; margin-right: .7rem!important;}
	.custom-accordion .baloon.personal-gain-baloon .custom-icon {margin-left: 0px;}
	.custom-accordion .card-body {padding: 1rem 1.25rem .75rem; min-height: 75px;}

	.accordion .popup .card-header .custom-icon {font-size: 30px; height: 30px; min-width: 30px; margin-right: .7rem!important;}

	.ml-2, .mx-2 {margin-left: .1rem!important;}
	.react-numeric-input, .form-control, .house-select, .region-select, input[type=password], input[type=text], select, textarea {min-width: 70px;}

	.house-popup input.short-input {min-width: 55px; max-width: 70px; max-height:30px;}
	.house-popup span.react-numeric-input {min-width: 55px; max-width: 74px!important;}
	.house-popup .card-body .house-numeric-input {min-width: 55px; max-width:74px;}
	.house-popup .card-body .house-numeric-input:nth-child(11) {max-width:85px;}
	.house-popup .card-body .house-numeric-input:nth-child(12) {padding-top: 10px; padding-left:4px;}

	.baloon.insulation-baloon .card-body .row:not(.thermoderm) .col-12 {max-width: 135px;}
	.baloon.insulation-baloon .card-body .row:not(.thermoderm) .col-6.pr-1 {max-width: 80px;}
	.baloon.insulation-baloon .card-body .row:not(.thermoderm) .col-6.pl-1 {max-width: 85px;}

	/*#roofGroup {left: -37px;}*/
	#flatRoofImg {left:-30px;}
	/*#shuttersWithoutBridgeImg {left: 0px;}*/

	/*.heating-device {right: -180px;}*/

	.heating-device#heat_pump,.heating-device#combined_electric_heat, .heating-device#combined_heat {left: 778px; bottom: 230px;}
	img#heating-device-pipe1 {right:-230px;}
	img#heating-device-pipe2 {right: -327px;}
	img#heating-device-monoblok {left: 914px;}

	img#heat_pump-air_air-electric {
		/*right: -170px;*/
		top: 320px;}
	img#heating-device-air_air {
		/*right: -290px; */
		top: 360px;}
	img#heat_pump-air_water-electric {right: -285px;}
	/*img#heating-device-glycol_water { right: -170px;}*/

	#wallRightInsulationImg {left:914px;}
	#wallDownLeftInsulationImg {top: 565px; }
	#wallUpLeftInsulationImg {top: 211px; }
	#bridgeUpInsulationImg{top: 292px;}
	#bridgeDownInsulationImg {top: 554px;}

	#leftFoundationImg {bottom: 143px;left: -14px;}
	#rightFoundationImg {bottom: 143px;}

	/*#wallHoleImg, #wallHoleRecInImg, #wallHoleRecOutImg {left:-24px;}*/
	#recuperatorImg {bottom: 219px;}
	#groundHeatExchangerImg {left:-65px;}
	/*#openWindowImg {left: 31px;}*/

	#shuttersWithoutBridgeImg {
		/*left: 0!important;*/
		top: 285px!important;width: 80px;}


	/*strzalki*/
	.arrows.sun-arrow {top: 175px;left: 106px;}
	.arrows.airing-arrow {top: 235px;left: 55px;}
	.arrows.windows-arrow {top: 298px;left: 56px;}
	.arrows.ventilation-arrow {top: 375px; left: 55px;}

	.arrows.collector-arrow {top: 25px;left: 260px;}
	.arrows.photovoltaic-arrow {top: 25px;left: 490px;}

	.arrows.heat-loss-arrow {top: 305px;right: -93px;}
	.arrows.heat-loss-arrow.gain {right: -97px;}
	.arrows.warm-water-arrow {top: 244px;left: 440px;}

	.arrows.roof-arrow {top: 27px;right: -29px;}
	.arrows.wall-arrow {top: 145px; right: -93px;}
	.arrows.air-conditioning-arrow {top: 237px;right: -93px;}

	.arrows.floor-arrow, .arrows.basement-arrow {top: 427px;left: 500px;}

	/*ikony*/

	.insolation-baloon.baloon.bs-popover-left .baloon-icon {right: 58px;}
	.dropdown-baloon-airing.baloon.bs-popover-left .baloon-icon {top: 24px; right: -154px;}
	.window-baloon.baloon.bs-popover-left .baloon-icon {top:25px;}
	.ventilation-baloon.baloon.bs-popover-left .baloon-icon {top:15px;}

	.collectors-icon.baloon .baloon-icon {top: -70px;left: -275px;}
	.photovoltaic-icon.baloon .baloon-icon { top: -70px;left: -95px;}
	.personal-gain-baloon.baloon .baloon-icon {left: -75px;top: 25px;}
	.dropdown-baloon-water.baloon .baloon-icon {right: 100px; top: 25px;}

	.insulation-baloon-roof.baloon.bs-popover-right .baloon-icon { top: 95px;}
	.insulation-baloon-wall.baloon.bs-popover-right .baloon-icon {left: 112px; top: 53px;}
	.dropdown-baloon-air-conditioning.baloon.bs-popover-right .baloon-icon {left: 8px;}
	.baloon.source-loss-baloon .baloon-icon {left: 112px;}
	.insulation-baloon-floor.baloon.bs-popover-right .baloon-icon {left: 70px;top: 65px;}
	.basement-baloon.baloon .baloon-icon {left: -40px;top: 445px;}


	.walls .row {margin-top: 0; margin-bottom: 0;}



	.optimizer-container .btn {padding: .3rem 0.75rem; font-size:0.9rem;}
	.ml-2, .mx-2 {
    margin-left: 0rem !important;}

	.optimizer-container-report .changer-wrapper > .change-row:first-child p {font-weight: 600; font-size: 12px;}

	.optimizer-container-report .irr-form div .house-numeric-input p {line-height: 100%;}

}

/***********************************/
/****do tabletów i trochę większych*/
/***********************************/

@media (min-width:1024px) and (max-width:1279px) {
	.energy-meter {
		left:50%;
	}

	.energy-meter > div {
		min-width:680px;
	}

	.energy-meter .btn {

		width: 212px;
		cursor: default;
	}
	.accordion .row {margin-bottom:5px; margin-top:5px;}

	.ml-2, .mx-2 {margin-left: .1rem!important;}
	.react-numeric-input, .form-control, .house-select, .region-select, input[type=password], input[type=text], select, textarea {min-width:75px;}

	input.short-input {max-width:75px;max-height:30px;}
	span.react-numeric-input {max-width: 75px!important;}
	.house-popup .card-body .house-numeric-input {max-width:140px;}
	.house-popup .card-body .house-numeric-input:nth-child(11) {max-width:108px;}
	.house-popup .card-body .house-numeric-input:nth-child(12) {padding-top: 10px;}

	/*panele lewe*/

	.custom-accordion .popup .custom-icon {margin-left:-3px;}
	.custom-accordion .card.photovoltaic-popup {border-top: 1px solid rgba(0,0,0,.2);}

	.custom-accordion .baloon .custom-icon {font-size: 24px; height: 24px; min-width: 24px; margin-right: .7rem!important;}
	.custom-accordion .baloon.personal-gain-baloon .custom-icon {margin-left: 0px;}
	.custom-accordion .card-body {padding: 1rem 1.25rem .75rem; min-height: 75px;}

	.accordion .popup .card-header .custom-icon {font-size: 30px; height: 30px; min-width: 30px; margin-right: .7rem!important;}

.house-popup .walls span.react-numeric-input {max-width:62px!important;}


	.optimizer-container .sum-total, .optimizer-container .totalSavings, .optimizer-container .totalSavingsPrice, .optimizer-container .totalSavingsIrr {padding-left: 0px; line-height: 120%;}
	.optimizer-container-report .cost-details {padding-right:2px; padding-left:2px;}
	.optimizer-container-report .change-details {max-width: 69%; flex: 0 0 69%;}
	.optimizer-container-report .savings-details {max-width: 31%;}
	.optimizer-container-report .results-summary {font-size: 1.3rem;}
	.optimizer-container-report .results-summary p .mr-1 {display:block;}

	.optimizer-container-report .housePopupUpperGroup .col-3 .font-weight-bold {line-height:110%; font-weight:normal!important;}
	.optimizer-container-report .housePopupUpperGroup .col-3 .d-inline-block,
	.optimizer-container-report .housePopupUpperGroup .col-3 .house-select-static,
	.optimizer-container-report .housePopupUpperGroup .col-3:nth-child(5) .lh-100 {line-height:110%; font-weight:bold; font-size: .9rem;}
	.optimizer-container-report .housePopupUpperGroup {padding-top: 15px;}


	.optimizer-container .btn {padding: .3rem 0.75rem; font-size:0.9rem;}
	.ml-2, .mx-2 {margin-left: 0rem !important;}
}

/***********************************/
/*do smartfonów i trochę większych**/
/***********************************/

@media (max-width:1023px) {

	body::-webkit-scrollbar {  display: none;}
	body::-webkit-scrollbar-button {  display: none;}
	body {-ms-overflow-style:none;}

	.card-body .row {margin-bottom:5px; margin-top:5px;}

	/*ekran startowy*/
	.welcome .modal-body p br {display:none!important;}
	.welcome .btn {min-width: 182px; margin: 5px 10px!important;}

	/*panele lewe*/

	.custom-accordion .popup .custom-icon {margin-left:-3px;}
	.custom-accordion .card.photovoltaic-popup {border-top: 1px solid rgba(0,0,0,.2);}

	.custom-accordion .baloon .custom-icon {font-size: 24px; height: 24px; min-width: 24px; margin-right: .7rem!important;}
	.custom-accordion .baloon.personal-gain-baloon .custom-icon {margin-left: 0px;}
	.custom-accordion .card-body {padding: 1rem 1.25rem .75rem; min-height: 75px;}

	.accordion .popup .card-header .custom-icon {font-size: 30px; height: 30px; min-width: 30px; margin-right: .7rem!important;}


	.react-numeric-input, .form-control, .house-select, .region-select, input[type="password"], input[type="text"], select, textarea {min-width:90px;}

	input.short-input {max-width:100px;max-height:30px;}
	span.react-numeric-input {max-width: 100px!important;}
	.house-popup .card-body .house-numeric-input {max-width:129px;}
	.house-popup .card-body .house-numeric-input:nth-child(11) {max-width:138px;}
	.house-popup .card-body .house-numeric-input:nth-child(12) {padding-top: 10px;}

	.custom-accordion .card-body {min-height: 100px}

	.baloon.insulation-baloon .card-body .row:not(.thermoderm) .col-12 {max-width: 135px;}
	.baloon.insulation-baloon .card-body .row:not(.thermoderm) .col-6.pr-1 {max-width: 80px;}
	.baloon.insulation-baloon .card-body .row:not(.thermoderm) .col-6.pl-1 {max-width: 85px;}


	.house-select, .region-select, input[type=password], input[type=text], select, textarea {height:calc(2em + .4rem)!important; font-size: 1rem; padding: 0.3rem;}

	p.showPopups.mb-0.mx-1.d-flex {display: none !important;}

.house-popup .walls span.react-numeric-input {max-width:62px!important;}

/*ukrycie optymalizatora*/
.balance-report > div > span {display:none;}

}

@media (max-width:680px) {

	body::-webkit-scrollbar {  display: none;}
	body::-webkit-scrollbar-button {  display: none;}
	body {-ms-overflow-style:none;}

	.card-body .row {margin-bottom:5px; margin-top:5px;}

	/*ekran startowy*/
	.welcome .modal-body p br {display:none!important;}
	.welcome .btn {min-width: 182px; margin: 5px 10px!important;}

	input.short-input {max-width:75px;max-height:30px;}
	span.react-numeric-input {max-width: 75px!important;}
	/*.house-popup .card-body .house-numeric-input {max-width:99px;}*/
	/*.house-popup .card-body .house-numeric-input:nth-child(11) {max-width:108px;}*/
	.house-popup .card-body .house-numeric-input:nth-child(12) {padding-top: 10px;}

	/*.baloon.insulation-baloon .card-body .row:not(.thermoderm) .col-12 {max-width: 135px;}*/
	/*.baloon.insulation-baloon .card-body .row:not(.thermoderm) .col-6.pr-1 {max-width: 80px;}*/
	/*.baloon.insulation-baloon .card-body .row:not(.thermoderm) .col-6.pl-1 {max-width: 85px;}*/



	.house-select, .region-select, input[type=password], input[type=text], select,
	textarea {height:calc(2em + .4rem)!important; font-size: 1rem; padding: 0.3rem;}

	p.showPopups.mb-0.mx-1.d-flex {display: none !important;}

}

.house-popup .card-body .house-numeric-input:nth-child(12) {padding-top: 0px!important; padding-left:15px;}
