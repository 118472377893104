@mixin center-both() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform:translate(-50%, -50%);
}

@mixin min-width($value) {
  @media screen and (min-width: #{$value}px) {
    @content;
  }
}

@mixin max-width($value) {
  @media screen and (max-width: #{$value}px) {
    @content;
  }
}
