@import '../../mixins.scss';

.energy-meter {
  width:auto; 
  z-index:6;
  position: absolute;
  left:50%;
  transform:translateX(-50%);
  @include max-width(1023){
    position: relative;
    top:45px;
    background: #fff;
    left:0;
    transform:none;
  }
  > div {
    height:45px;
    @include max-width(1023) {
      justify-content: center;
      background: #fff;
    }
    @include max-width(640){
      height:auto;
      padding:10px 0;
    }
  }
  .spacer {
    @include max-width(640){
      display:table;
      width:100%;
    }
  }
  .active {
    .value {
      color:#ec6d11;
    }
  }
  .value {
    font-size:1.3rem;
  }
  .btn {
    width:225px;
    cursor:default;
    @include min-width(1280) {
      @include max-width(1300) {
        width: 213px;
      }
    }
    @include min-width(1280) {
      width:213px;
    }
    &.btn-whites {
      background-color:#fff!important;
    }
  }
  .chart {
    width:44px;
    height:44px;
    .recharts-tooltip-wrapper {
      z-index:1;
      white-space: nowrap;
    }
    .recharts-pie-labels {
      display: none;
    }
    .recharts-wrapper path {
      stroke:white;
      &:hover {
        transform: scale(1.2);
        transform-origin: center;
      }
    }
    &.circle {
      .recharts-wrapper path {
        stroke: transparent;
      }
    }
  }
}
.toggle-active {
  color:#ec6d11;
  &.active {
    color:black;
  }
}
.balance-report {
  font-size:1.4rem;
  @include max-width(640){
    display:none!important;
  }
}