$body-bg: #fff;
$body-color: #333c45;
$font-family-base: 'Roboto', Arial, Tahoma, sans-serif;

$link-color: #46d1e7;
$link-hover-color: #588cb3;
$link-decoration: none;
$link-hover-decoration:none;

$primary: #75ae2e;
$secondary: #ec6d11;
$color-success: #0fa34d;
$color-error: #fd1f34;
$color-light: #ededed;
$color-dark: #4c4c4c;
$color-red: #e23235;
$color-gray: #c2c2c2;

$border-color: $primary;
$btn-padding-x:2rem;
$btn-padding-y:0.3rem;
$btn-border-radius:20px;
$btn-border-radius-lg:20px;

$btn-font-family:$font-family-base;
$card-border-radius:0;
$card-border-width:0;
$popover-body-color: $body-color;


$theme-colors: (
        "primary": #75ae2e,
        'secondary': #ec6d11,
        'success': #0fa34d,
        'danger': #fd1f34,
        'warning': #ffc107,
        'info': #17a2b8,
        'dark':#4c4c4c,
        'light': $color-light,
        'whites': #ffffff,
);

$btn-box-shadow: none!important;
$btn-active-box-shadow: none;
$btn-focus-box-shadow:none!important;

$input-focus-box-shadow: none!important;
$custom-file-focus-box-shadow:none!important;
$enable-gradients: true;

$custom-control-indicator-bg: gray;
$custom-control-indicator-color:white;
$custom-control-indicator-box-shadow:none;
$custom-control-indicator-focus-box-shadow:none;

$input-focus-box-shadow: none;

$input-padding-y:0.2rem;
$input-padding-x:0.2rem;

input[type="text"], input[type="password"], select, textarea, .region-select, .house-select {
  @extend .form-control;
  border-color: $border-color;
  min-height:24px;
  height: calc(1.5em + .4rem + 0px)!important;
  padding: .1rem;
}

@import '~bootstrap/scss/bootstrap.scss';



body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #7d6b4f; font-size: 0.9rem; font-family:Roboto, Arial, Tahoma, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form-control, input[type=password], input[type=text], select, textarea {
  padding: .1rem;
}