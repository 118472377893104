@import '../../mixins.scss';

#plotContainer {
    position: fixed;
    top:46px;
    left:50%;
    width:1070px;
    height:auto;
    padding:20px;
    z-index: 20000;
    background: #fff;
    max-width:100%;
    transform: translate3d(-50%, 0, 0);
    overflow-y: scroll;
    max-height: 88vh;
    @include max-width(500){
        padding:10px;
    }
    @include max-width(1000){
     width:100%;
    }
    .recharts-default-tooltip {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        .recharts-tooltip-label {
            font-weight:bold;
        }
    }
}
.highest {
    z-index:20001!important;
}
.chart {
    height:400px;
    .recharts-tooltip-item {
        padding-top:2px!important;
        padding-bottom:2px!important;
    }
    .recharts-tooltip-item-list {
        display:flex;
        flex-direction: column-reverse;
    }
}

.aggregated-chart {
    @include max-width(500){
        display:none;
    }
}

.recharts-wrapper {
    position: static!important;
    @include max-width(1000){

    }
    .recharts-bar-rectangle path {
        stroke: rgba(128, 128, 128, 0.58);
    }
}

.recharts-default-legend {
    display: flex;
    flex-wrap:wrap;
    flex-direction: column-reverse;
}

g.recharts-layer.recharts-bar-rectangles {
    transform: translateY(-1px);
}

.passive-logo {
    max-width: 50px;
    position: absolute;
    right: 20px;
    top: 0;
}

.recharts-legend-wrapper {
}
.energy-cost {
    font-size:1.5rem;
    color:#ec6d11;
}
#energyClassCodeLabel {
    font-size: 3rem;
    margin-left: 0.5rem;
    position: absolute;
    right: 20px;
    top: 20px;
    font-weight:bold;
    line-height: 1;
}
.energy-class-value {
    font-size: 1.6rem;
    line-height: 1.2;
    padding-right:20px;
}

.pie-chart-groups {
    width: 180px;
    display: flex;
    height: 120px;
    position: absolute;
    bottom: -20px;
    right: 10px;
    .chart {
        max-width: 120px;
        height: 100%;
        .recharts-tooltip-wrapper {
            transform:none!important;
        }
        .recharts-default-tooltip {
            transform:translate3d(-20px, -30px, 0);
            min-width:170px;
            margin-left:-50px;
        }
        .recharts-pie-labels {
            display:none;
        }
    }
}

.consumption-icon {
    font-size: 2rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}