@import '../../mixins';

#root {
  @include min-width(1024) {
    overflow: hidden;
  }

};

.container-fluid {
  .modal-backdrop {
    top:45px;
  }
  .firstCalculation + .modal-backdrop {
    top:0px;
  }
}
#content_container {
  background-color: #7d6b4f!important;
  @include max-width(1366){
    background-size:auto 666px!important;
  };
  @include max-width(1023){
    background-position: left bottom;
  }
  &.upFloor {
    background-size: auto 640px!important;

    .housePanel-container .house-popup,
    .housePanel-container .heating-popup,
    .housePanel-container .photovoltaic-popup {
      top:4px;
    }
  }
  #housePanel {
    &.upFloor {
      margin: 46px 0 0 43px;
      @include max-width(1100) {
        margin: 46px 0 0 auto;
      }
    }
  }
}
.housePanel-wrapper {
  width:calc(100% - 310px);
  margin: 0 0 0 auto;
  max-width: 100%;
  @include max-width(1365){
    background-size:100% 689px!important;
    width:100%;
    //min-width:1000px;
    max-width:none;
    position: relative;
  };

}
.housePanel-container {
  width:1000px;
  max-width:100%;
  margin:0 auto 0 auto;
  position: relative;
  @include max-width(1023){
    display:none;
  }
  @include max-width(1023){
    max-height:650px;
  }

  .house-popup, .heating-popup, .photovoltaic-popup {
    position: absolute;
    top: -20px;
    z-index: 1039;
    width: 310px;
    left: 32%;
    display: none;
    transform: translateX(-100%);
  }
  .heating-popup {
    left:34%;
    transform:translateX(0)
  }
  .photovoltaic-popup {
    left:67%;
    transform:translateX(0);
  }
}

#housePanel {
  margin: 70px 0 0 43px;
  @include max-width(1100) {
    margin:70px 0 0 auto;
  }
  width:915px;
  max-width:100%;
  position: relative;
  @include max-width(1649){
    transform:scale(0.65);
    transform-origin: top;
  };


  img {
    position: absolute;
  }
  .houseDropDownList {
    img {
      position: static;
    }
    input {
      height:auto;
    }

  }
  &.downFloor {
    #wallRightInsulationImg {
      height:729px!important;
    }
    #wallDownLeftInsulationImg {
      height:376px!important;
    }
    #wallUpLeftInsulationImg {
      height:81px!important;
    }
    &.upFloor {
      #wallRightInsulationImg {
        height:938px!important;

      }
      #wallDownLeftInsulationImg {
        height:376px!important;
      }
      #wallUpLeftInsulationImg {
        height:290px!important;
      }
    }
  }
  &.upFloor {
    #wallRightInsulationImg {
      top:0px!important;
    }
    #wallUpLeftInsulationImg {
      top:0px!important;
      height:290px!important;
    }
  }
  &.groundType {
    &.foundationsInsulated {
      #wallRightInsulationImg {
        height:580px!important;
      }
      #wallDownLeftInsulationImg {
        height:227px!important;
      }
    }
    &.upFloor {
      &.foundationsInsulated {
        #wallRightInsulationImg {
          height:787px!important;
        }
        #wallDownLeftInsulationImg {
          height:225px!important;
        }
      }
    }
  }
}

#roofGroup {
  position: absolute;
  top:52px;
  left:-42px;
}

#roofImg {
  top:22px;
  left: 0px;
  z-index: 2;
  &.flat {
    top:17px;
  }

  &.complicated {
    top:29px;
  }


}

.house-img {
  margin:0 auto;
  display:block;
}

#flatRoofImg {
  top: 208px;
  left: 7px;
  z-index: 2;
}

#wallRightInsulationImg {
  top: 208px;
  left: 100%;
}

#wallDownLeftInsulationImg {
  top: 561px;
  right: calc(100% - 1px);
}

#bridgeUpInsulationImg {
  top: 290px;
  left: 0;
}

#bridgeDownInsulationImg {
  top: 553px;
  left: 0;
}

#wallUpLeftInsulationImg {
  top: 209px;
  right: 100%;
}


#roofInsulationImg {
  bottom:727px;
  left: 0;
}

#floorInsulationImg {
  top: 732px;
  left: 0;
}

#recuperatorImg {
  bottom: 224px;
  left: 32px;
  z-index: 2;
}

#mechanicalImg {
  bottom: 237px;
  left: 35px;
  z-index: 2;
}

#groundHeatExchangerImg {
  bottom: 125px;
  left: -20px;
  z-index: 2;
}

#bathImage {
  //right: 222px;
  top: 560px;
  z-index: 2;
  left:489px;
}

#openWindowImg {
  left: 33px;
  top: 309px;
  z-index: 2;
}

#wallHoleImg, #wallHoleRecInImg, #wallHoleRecOutImg {
  bottom: 234px;
  //left: -11px;
  z-index: 2;
  position: absolute;
}

#wallHoleRecInImg {
  bottom: 260px;
}

#leftFoundationImg, #rightFoundationImg {
  bottom: 139px;
  left: 876px;
  z-index: 3;
}
#leftFoundationImg {
  left: -14px;
}

#flatCollector1, #flatCollector2, #flatCollector3, #flatCollector4 {
  top: 55px;
  left: 397px;
  z-index: 3;
  width: 195px;
}

#flatCollector2 {
  left: 445px;
}
#flatCollector3 {
  left: 495px;
}
#flatCollector4 {
  left: 545px;
}
#vacuumCollector1, #vacuumCollector2 {
  top: 55px;
  left: 397px;
  z-index: 2;
  width: 195px;
}
#vacuumCollector2 {
  left: 495px;
}

.custom-accordion {
  position: absolute;
  left: 0;
  top:46px;
  z-index:1040;
  background: #fff;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  width:310px;
  height:calc(100vh - 46px);
  @include max-width(1023){
    position: relative;
    width: calc(100% + 30px);
    left: -15px;
    height: calc(100vh - 91px);
  }
  .baloon {
    position: static;
    border:none;
    border-radius:0;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    width: 100%!important;
    max-width:none;
    margin:0;
    &.hidden {
      border-top: 1px solid rgba(0, 0, 0, 0.2)!important;
      .popover-body
      {
        opacity:1;
        visibility:visible;
      }
    }
    &.warning {
      border: 2px solid red!important;
      background: rgba(255, 0, 0, 0.21);
      + .warning {
        border-top: 0 solid red!important;
      }
    }
    &.success {
      border: 2px solid #468000 !important;
      background: rgba(0, 255, 34, 0.21);
      + .success {
        border-top: 0 solid red!important;
      }
      .card-body {
        padding-bottom:0;
      }
    }
    .baloon-icon, .arrow, .fa-info-circle {
      display: none!important;
    }
  }
  .popover-body {
    padding:0;
    width: 100%;
  }
  .card {
    background:none;
    &:last-of-type {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
  .card-header {
    cursor:pointer;
  }
  .card-body {
    padding: 0.5rem 1.25rem;
  }
  .custom-icon {
    font-size: 30px;
    height: 30px;
    width: auto;
    min-width: 16px;
  }
}

.showPopups {
  @include max-width(1023){
    display:none;
  }
}

#content_container {
  &.hidePanels {
    .custom-accordion {
      display:none;

      .accordion {
        display:none;
      }
    }
    .housePanel-wrapper{
      width:100%;
    }
    .house-popup, .heating-popup, .photovoltaic-popup {
      display:block;
      z-index:1070;
      border-bottom:none;
    }
  }
  &.showPanels {
    .housePanel-wrapper {
      .baloon {
        background: none;
        border: none !important;
        .popover-body, .arrow {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
}
.isolation {
  background-image: url('../../assets/images/insulation.png');
  background-repeat: repeat;

}

.heating-device {
  right: 48px;
  bottom: 230px;
  z-index: 111;
  height: 150px;
  left:785px;
  &#combined_heat{
    width: auto;
    bottom: 196px;
  }
  &#combined_electric_heat{
    width: auto;
    bottom: 196px;
  }
  &#heat_pump {
    right: 90px;
    bottom: 230px;
  }
}

img#heating-device-pipe1 {
  bottom: -203px;
  right: 55px;
  z-index: 0;
  height: auto;
  width: 11px;
  left:850px;
}
img#heating-device-pipe2 {
  bottom: 121px;
  right: -69px;
  z-index: 0;
  height: 11px;
  display: none;
  left:850px;
}

img#electricity-radiator-electric {
  left:725px;
}
img#electricity-accumulating-electric {
  left:715px;
}
img.solid_fuel_boiler
{
  left:780px;
}
img.no_water_jacket, img.water_jacket {
  left:732px;
}

img#heating-device-monoblok {
  right: -10px;
}
img#heating-device-glycol_water {
  bottom: 70px;
  z-index: 0;
  left:811px;
}
img#heat_pump-air_air-electric {
  left: 707px;
  top: 270px;
  width:150px;
}
img#heating-device-air_air {
  left: 915px;
  top: 350px;
  height: 50px;
}
img#pompa-ciepla-wiatraczki {
  //right: -10px;
  left:915px;
  top: 579px;
  height: 86px;
}

img#heat_pump-air_water-electric {
  left:915px;
}

.firstCalculation {
  .firstCalculationStep1, .custom-accordion, .firstCalculationStep2, .firstCalculationStep3 {
    z-index:1090!important;
  }
  .custom-accordion {
    .card {
      display:none!important;
    }
    .firstCalculationStep1, .firstCalculationStep2, .firstCalculationStep3 {
      display: flex!important;
    }
  }
}
