@import "mixins";

html {
  font-size: 13px;
  font-family:"Roboto", Arial, sans-serif;
}

body {
  margin:0;
  padding:0;

}

h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto Condensed", sans-serif;
}
h1, .h1 {font-size: 1.75rem; text-align: center;}
.h2, h2 {font-size: 1.5rem;}

ol, ul {
  padding-left: 30px;
}

select {max-width:100%;}


.btn {
  cursor: pointer;
  &.btn-lg {

  }
}

.font-weight-semibold {
 font-weight: 600!important; 
}

.mb-3, .my-3 {
  margin-bottom: 0.3rem!important;
}

.navbar-header {

  .row {
    background: #ededed;
    height: 46px;
    position: absolute;
    width: 100%;
    z-index: 3;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  .logo {
    position: absolute;
    top: 8px;
    z-index: 1;
    max-width: 150px;

  }
}

.popover {
  z-index:1039;
  &[role="tooltip"]{
    z-index:2000;
  }
}

.pointer {
  cursor:pointer!important;
}

.react-slidedown {
  overflow:hidden;
}

.select-appearance {
  -webkit-appearance: menulist;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  right: 15px;
}

.help-icons {
  font-size:1.4rem;
}
.custom-icon {
  font-size:16px;
  height: 16px;
  width:auto;
}
.icon-caret-toggle {
  &.reversed {
    transform:rotate(180deg);
  }
}

select.form-control {
  font-size: 0.9rem;
  font-family:"Roboto", Arial, sans-serif;
}


.change-view {
  font-size:.9rem;
  margin-right:0.5rem;
}

.lh-100 {
  line-height: 100%;
}

.unit-small {
  width:20px;
}

.w-20 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20% !important;
  flex: 0 0 20% !important;
  max-width: 20%;
  padding-left:15px;
  padding-right:15px;
}
.w-40 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 40% !important;
  flex: 0 0 40% !important;
  max-width: 40%;
  padding-left:15px;
  padding-right:15px;
}
.w-60 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 60% !important;
  flex: 0 0 60% !important;
  max-width: 60%;
  padding-left:15px;
  padding-right:15px;
}


#shuttersWithoutBridgeImg {left: 0;}
.react-numeric-input {max-height: 24px;}

/*do wszystkiego*/



@media (min-width:1366px){

  /*ikonki*/

  .insolation-baloon.baloon.bs-popover-left .baloon-icon {right:-45px; }

  .insulation-baloon-roof.baloon.bs-popover-right .baloon-icon {left: -158px;top: 103px;}
  .insulation-baloon-wall.baloon.bs-popover-right .baloon-icon {left: -45px;top: 74px;}
  .dropdown-baloon-air-conditioning.baloon.bs-popover-right .baloon-icon {left: -25px;}

  /*strzalki*/

  .arrows.roof-arrow {top:37px; left:70%;}
  .arrows.wall-arrow {top: 165px;}

  /*dymki i ich elementy*/

  .hidePanels .baloon.insolation-baloon .popover-body > div.content .card-body .row p.col-12 {display:none!important;}
  .hidePanels .baloon.insolation-baloon.bs-popover-left .arrow {top:70%;}
  .hidePanels .baloon.ventilation-baloon.bs-popover-left .arrow {top:24px;}

  .hidePanels .baloon.insulation-baloon-roof {top:59px;}
  .hidePanels .baloon.insulation-baloon-wall {top: 140px;}
  .hidePanels .baloon.dropdown-baloon-air-conditioning {top: 249px;}
  .hidePanels .baloon.source-loss-baloon {top: 328px;}
  .hidePanels .baloon.source-loss-baloon .popover-body h3.col-10 {flex: 0 0 87.33333%; max-width:87.33333%}
  .hidePanels .baloon.insulation-baloon-floor {top:400px;}

  /*inne*/
  #content_container {background-size:auto 666px!important;}

  #shuttersWithoutBridgeImg {
    //left: 43px!important;
    top: 293px!important;
    height: auto!important;
    width: 85px!important;
  }

  #bridgeDownInsulationImg {
    top: 554px;
  }

  #floorInsulationImg {width: 915px!important;}

}

@media (max-width:1023px) {

  body::-webkit-scrollbar {  display: none;}
  body::-webkit-scrollbar-button {  display: none;}
  body {-ms-overflow-style:none;}

  .house-select, .region-select, input[type=password], input[type=text], select, textarea {height:calc(2em + .4rem)!important; font-size: 1rem; padding: 0.3rem;}

  .showPopups {
    display: none!important;
  }

}

@media (min-width:1024px) and (max-width:1365px) {

  .housePanel-wrapper {
    width: calc(100% - 310px);
    margin: 0 0 0 auto;
    max-width: 100%;
  }

  .housePanel-container {
    width: 1000px;
    max-width: 100%;
    margin: 0 auto 0 -74px;
  }

  //#roofGroup {left: -37px;}
  #flatRoofImg {left:-30px;}
  //#shuttersWithoutBridgeImg {left: 0px;}

  .heating-device#heat_pump,.heating-device#combined_electric_heat, .heating-device#combined_heat {left: 778px; bottom: 230px;}
  img#heating-device-pipe1 {right:-230px;}
  img#heating-device-pipe2 {right: -327px;}
  img#heating-device-monoblok {left: 914px;}

  #wallRightInsulationImg {left:914px;}
  #wallDownLeftInsulationImg {top: 565px;}
  #wallUpLeftInsulationImg {top: 211px;}
  #bridgeUpInsulationImg{ top: 292px;}
  #bridgeDownInsulationImg { top: 554px;}

  #leftFoundationImg {bottom: 139px;left: -14px;}
  #rightFoundationImg {bottom: 139px;}

  //#wallHoleImg, #wallHoleRecInImg, #wallHoleRecOutImg {left:-24px;}
  #recuperatorImg {bottom: 219px;}

  //#openWindowImg {
  

  //#shuttersWithoutBridgeImg {    left: -4px;}


  .arrows.sun-arrow {top: 175px;}
  .arrows.windows-arrow {top: 298px;}
  .arrows.ventilation-arrow {top: 375px;}

  .arrows.floor-arrow {top: 427px;left: 500px;}

  .arrows.roof-arrow {top: 27px;}
  .arrows.wall-arrow {top: 145px;}

  .react-numeric-input, .form-control, .house-select, .region-select, input[type=password], input[type=text], select, textarea {min-width:75px;}

}
