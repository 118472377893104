@import '../../mixins.scss';

.energy-class-grade {
  font-size:2rem;
}
.energy-class-value {
  font-size:1.2rem;
}
.house-specs {
  width:750px;
  margin:0 auto;
  font-size:12px;
  @include max-width(750){
    width:auto;
  }
  table {
    width:100%;
    max-width:100%;
    .energy-class-value {
      @include max-width(480){
        font-size:0.7rem;
      }
    }
  }
}