.welcome {
  .btn {
    min-width:182px;
    margin: 0 10px !important;
  }
}

.modal-backdrop {
  z-index:1089;
}
.modal {
  z-index: 1090;
}
.modal-content {
  border:none;
}
.release {
  position: absolute;
  top:0;
  left:10px;
}
.modal-dialog {
  margin:0 auto;
  position: absolute;
  top:50%;
  left:50%;
  transform: translate3d(-50%, -50%, 0) !important;
  .modal-header{
    padding-top:0;
    padding-bottom:0;
    border:0;
    position: relative;
    justify-content: flex-start;

    img.flag{
      margin-top:10px;
      margin-right:10px;
      cursor:pointer;
    }
  }
  .modal-footer {
    border:none;
  }
}
img.logo {
  display: block;
  margin:0 auto;
}

.navbar-brand {
  display:block;
}

.welcome-image {
  //background: url('../../assets/images/welcome-image.jpg') no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1088;
}

.partner-logo{
  max-height:50px;
  max-width:100%;
}
