/***********************************/
/***********do wszystkiego**********/
/***********************************/

body {background-color: #7d6b4f; font-size: 0.9rem; font-family:Roboto, Arial, Tahoma, sans-serif;}

body, select.form-control, .form-control, .house-select, .region-select, input[type=password], input[type=text], select, textarea, .popover-body {color: #000000;}

h1, .h1 {font-size: 1.75rem; text-align: center;}
.h2, h2 {font-size: 1.5rem;}
.text-small {line-height: 110%; padding-bottom: 0.25rem;}

@media (max-width: 1649px) {

	#content_container.upFloor.downFloor { background-size: auto 656px !important;}
}

select.form-control, .form-control, .house-select, .region-select, input[type=password], input[type=text], select, textarea, .short-input, #houseStandardDropDownList, input.insulation-input {color: #000000; font-size: 0.9rem!important;}
input.short-input {color: #000000; font-size: 0.9rem!important;}

.arrows .percent-value, .arrows.loss .percent-value, .arrows.gain .percent-value {font-size: 12px; vertical-align:middle;}
.arrows.living-needs-arrow .percent-value, .arrows.ventilation-systems-arrow .percent-value {background: #ccc!important;}

.row, .thermoderm.row {margin-bottom: 0px;margin-top: 0px;}
.thermoderm.row {margin-bottom: 0px!important; margin-top:10px!important; padding-top:0px!important;}

.warning-box {padding-left: 0px;}
.warning-box .text-warning {color: #ec6d11!important;}

.warning-alert {
	margin: 0 0 10px;
	line-height: 115%;
	padding-top: 5px;
	padding-bottom: 5px;
}
.welcome.modal .modal-content .modal-body {padding: 2rem;}
.welcome.modal .modal-content .modal-body p.release {top: 17px; left: 13px; font-size: 0.75rem;}
.navbar-brand, img.logo { max-width: 196px; margin: 0 auto 20px; padding-top:0px;}

.navbar-header img.logo {max-width: 120px; margin-left: 0px; left: 15px; padding-top:0px;}

img.logo.logoue {margin-right: -12px; margin-top: -32px; margin-bottom: 20px;}

.welcome.modal .modal-content .partner-logo {margin-bottom:20px;}
.welcome.modal .modal-content .partner-logo#logo-wfos { margin-left: -6px;}
.modal-content .modal-footer .logotypy {width: 101%!important;}

#logo-fer {max-height: 40px;}

.modal-content .modal-footer>:not(:first-child) {margin-left: 1.1rem}

.energy-meter .btn.rounded-0 {padding-left: 10px!important;}
.energy-meter .btn .px-1 > p.mb-0 {font-size: 0.87rem; text-align:left; padding-top: 7px; line-height: 100%;}
.energy-meter .btn .px-1 .justify-content-center {justify-content: left!important;}

#content_container {background-color: #7d6b4f!important;}
.hidePanels .baloon.insolation-baloon .popover-body > div.content .card-body .row p.col-12 {display:none!important;}
.hidePanels .baloon.insolation-baloon.bs-popover-left .arrow {bottom: 15px; top: auto;}

.hidePanels .baloon.ventilation-baloon.baloon.bs-popover-left .arrow {top: 64px;}
.hidePanels .baloon.ventilation-baloon.baloon.bs-popover-left p.mb-0 .fa-info-circle {margin-left: -130% !important;}
.custom-accordion .baloon.ventilation-baloon.baloon.bs-popover-left p.mb-0 .fa-info-circle {display:block!important;}
.custom-accordion .baloon.ventilation-baloon p.mb-0 {text-align:left} /*??*/

.heating-device#combined_electric_heat, .heating-device#combined_heat {width:auto;}
.heating-device {z-index:2;}

.baloon .content p {line-height: 110%; padding-bottom: 1px;}
.popover-body {padding: 0.25rem 0.5rem;}

.insulation-baloon .card-body .row .col-12 {max-width:85px; padding-right:0px;}
.insulation-baloon .card-body .row .col-6.pr-1 {max-width:46px; padding-left:5px; padding-top:9px; padding-right:0px;}
.insulation-baloon .card-body .row .col-6.pl-1 {max-width:63px;
	padding-left: 0px!important; padding-top:9px; }
.baloon.insulation-baloon .card-body .row:not(.thermoderm) .col-12.u0 p.pr-2 {max-width:115px; padding-bottom: 2px;}
.baloon.insulation-baloon .card-body .row:not(.thermoderm) .u0 {padding-bottom:2px;}

.baloon.insulation-baloon.insulation-baloon-roof .card-body .row:not(.thermoderm) .u0, .baloon.insulation-baloon.insulation-baloon-floor .card-body .row:not(.thermoderm) .u0  {display:none!important;}

.dropdown-baloon-water .content .card-body {padding-top:7px; padding-bottom:0px;}
.dropdown-baloon-water .content .card-body p {padding-bottom:3px;}
.dropdown-baloon-water .content .card-body label p {max-width: 80%;}

/*nowe*/
#content_container.hidePanels .heating-popup, #content_container.hidePanels .house-popup, #content_container.hidePanels .photovoltaic-popup {
	border-radius: 5px;
	display: block;
	z-index: 1070;
	border: 1px #c8c8c8 solid;
	border-bottom: 1px #c8c8c8 solid!important;
	box-shadow: 0px 0px 5px rgba(0,0,0,0.1);}

#content_container #PhotovoltaicPopup2 #heatPopupMainGroup div:nth-child(2) div p.lh-100 {max-width: 155px!important;}

#content_container #PhotovoltaicPopup2 #heatPopupMainGroup h5 {font-size: 18px;}

#content_container #PhotovoltaicPopup1 #heatPopupMainGroup .col-4 p, #content_container #PhotovoltaicPopup2 #heatPopupMainGroup .col-4 p {padding-top: 7px!important; line-height: 100%; vertical-align:top;}

/*inne*/

#housePopupUpperGroup .house-numeric-input p.text-small {line-height: 110%; padding-bottom: 2px;}

.house-numeric-input .react-numeric-input {max-width:55px;}

.house-select img.house-icon {margin-top:-5px;}

.select-appearance {-webkit-appearance: menulist-button;border: 0px #fff solid; width: 84%;
	height: 88%;
	right: 16px;
	top: 1px;}

.walls .btn-secondary {margin-top:5px;}
.wall-row p.text-small {margin-top: -10px;}
.wall-row-0 p.text-small {}
.house-popup .card-body h3.mb-0, .heating-popup .card-body h3.col-12, .heating-popup .card-body h4, #content_container #PhotovoltaicPopup2 #heatPopupMainGroup h5:nth-child(3), #content_container #PhotovoltaicPopup2 #heatPopupMainGroup div:nth-child(8) h5, #content_container #PhotovoltaicPopup2 #heatPopupMainGroup h5 {
	font-size: 17px;
	margin-top: 4px;
	border-top: 1px solid rgba(0,0,0,.05);
	padding-top: 7px;
	padding-bottom: 15px;
}

.house-popup .card-body .walls h3.mb-0 {padding-bottom:5px;}
.house-popup .card-body .walls .row p.mb-2 {padding-bottom:10px;}

#PhotovoltaicPopup2 #heatPopupMainGroup div:nth-child(9).col-12 h5 {
	margin-right: -15px;
	margin-left: -15px; padding-left: 15px; padding-right: 15px; margin-top: 7px;}

#heatPopupMainGroup div.col-4.d-flex.pr-0.align-items-center.mb-2:nth-child(16) span:first-child {display:none;}
#heatPopupMainGroup div.col-4.d-flex.pr-0.align-items-center.mb-2:nth-child(16) span:nth-child(2)::before {content: 'Cena ';}
#heatPopupMainGroup div.col-4.d-flex.pr-0.align-items-center.mb-2:nth-child(16) span:nth-child(2) {margin-right: -12px; }

#HeatingPopup1 {border-top: 1px solid rgba(0,0,0,.2)!important}
#HeatingPopup1.success {border-top:2px solid green!important}
#HeatingPopup1.warning {border-top:2px solid red!important}
#HousePopup1 {border-bottom: 0px!important;}

.heating-popup .card-body h4 {border-top: 0px;}
.heating-popup #maxPowerRichText {font-weight: 700;}
.heating-popup .card-body .align-items-center .heat, .heating-popup .card-body .align-items-center .water, .heating-popup .card-body .align-items-center .residual-needs {font-size: .75rem; max-width: 35%;}
.heating-popup .card-body .align-items-center .water{padding-left: 20px;}

/*#HeatingPopup2 .card-body #warmWaterBorderContainer::before {width:100%; content: "Ogrzewanie wody";     font-size: 18px; margin-top: 4px; border-top: 1px solid rgba(0,0,0,.05); padding-top: 3px; padding-bottom: 5px; font-family: Roboto Condensed,sans-serif; padding-left: 15px;}*/

#heatPopupMainGroup > div:nth-child(12) {display:none!important}
#heatPopupMainGroup > div:nth-child(13) {margin-left:103px; margin-top:-26px;}


.heating-input, .small-input, .short-input {max-width: 70px;}
.walls .wall-row .short-input {max-width: 60px;}
.wall-row.wall-row-0 .fa-times {top:15px!important;}

.house-numeric-input.pl-2 {padding-left: 0.1rem!important;}
.house-numeric-input .text-small.ml-2 {margin-left: 0.2rem!important;}

.ventilation-baloon .form-control, .dropdown-baloon-airing .form-control {min-width: 165px;}

.custom-accordion .card.photovoltaic-popup {border-top: 1px solid rgba(0,0,0,.2);}

.personal-gain-baloon .content p {position:relative; top: -17px; padding-bottom: 13px;}
.personal-gain-baloon .content .card-body .align-items-center {position:relative; top:20px;}

.card-body .thermoderm.row .col-12 {max-width:100%!important; width: 100%}
.card-body .thermoderm.row h3.col-12 {padding-top:10px; margin-top: 0px;
	padding-bottom: 2px; font-size: 17px; }
.card-body .thermoderm.row .col-6.pr-1 {padding-top:4px}

.popup.house-popup#HousePopup2.firstCalculationStep1 .card-body {min-height: 370px}
#HeatingPopup2 .card-body, #HousePopup2 .card-body {min-height: 340px;}


#flatCollector1, #flatCollector2, #flatCollector3, #flatCollector4, #vacuumCollector1, #vacuumCollector2 {
	top: 70px;
	left: 417px;
	width: 170px;}

img#pompa-ciepla-wiatraczki, img#heating-device-air_air {right: 1px;}
#wallRightInsulationImg {top:210px;}

#mechanicalImg {bottom: 239px;}

#warmWaterBorderContainer hr {margin-bottom: 0px;}


.baloon.dropdown-baloon-air-conditioning .card-body > div:first-child {max-width: 50px;
	float: left; padding-top: 6px; padding-right: 10px;}

.baloon.dropdown-baloon-air-conditioning .card-body > div:first-child p {display:none;}

.custom-accordion .baloon.insulation-baloon .card-body .row:not(.thermoderm) .u0 {padding-bottom: 5px;}
.custom-accordion .baloon.insulation-baloon .card-body .row:not(.thermoderm) .u0 > p:first-child {max-width:95px;}
.custom-accordion .baloon.insulation-baloon .card-body .fa-info-circle  {right: 12px; display: block!important;}


.arrows.heating-systems-arrow, .arrows.ventilation-systems-arrow, .arrows.living-needs-arrow {color: #888}

/*optymalizator*/

.optimizer-container {background-color:#ffffff; border-bottom: 1px solid #bebebe;}

.irr-form {padding-top: 2px!important; display: block; padding-bottom: 5px!important; border: 0px;}
.optimizer-container-report .irr-form div div.col-3:nth-child(3), .optimizer-container-report .irr-form div div.col-3:nth-child(4) {display:none!important;}

.irr-form .text-medium {max-width: 135px;}





h3.optimizer-header {font-size: 1.35rem; padding-top: 5px; padding-bottom: 5px;}
/*.optimizer-container-report .changer-wrapper > .change-row:first-child p {
	font-size: 16px;
    margin-top: 0px;
	margin-bottom: 0px;
    border-top: 0px solid rgba(0,0,0,.05);
    padding-top: 0px;
    padding-bottom: 0px;
	font-family: Roboto Condensed,sans-serif;
	text-align: left!important;
	line-height:105%;}*/

.optimizer-container-report .changer-wrapper .change-row .row-header p {font-weight: 400!important;line-height: 110%; text-align: left!important; margin-bottom: 0.13rem!important}
.optimizer-container-report .changer-wrapper .change-row .row-header p.col-6, .optimizer-container-report .changer-wrapper .change-row .row-header h3, .optimizer-container-report .changer-wrapper .config-header p, .optimizer-container-report .changer-wrapper .investments-cost > p, .optimizer-container-report .changer-wrapper .savings-header p {font-family:Roboto Condensed, sans-serif; font-size: 16px; margin-bottom: 0rem;}

.optimizer-container-report .changer-wrapper .change-row-header .change-details .row .col-6:first-child .col-6:nth-child(2) > p {font-family: Roboto, sans-serif; font-size: 0.9rem;}

.optimizer-container-report .changer-wrapper .change-row-header .col-8 .investments-cost {background:#ffff00;  border-left: 1px solid rgba(0,0,0,.2);}
.optimizer-container-report .changer-wrapper .change-row-header .change-details .row .col-6:first-child .col-6:nth-child(2) {background-color: transparent}

.optimizer-container-report .changer-wrapper .savings {background:rgba(0,255,34,.21);  border-left: 1px solid rgba(0,0,0,.2);}

.optimizer-container-report .changer-wrapper .savings-rates {background:rgba(0,132,255,.21);  border-left: 1px solid rgba(0,0,0,.2);}

.optimizer-container-report .changer-wrapper .change-row .change-details .row .col-6.cost-details {background-color:rgba(255,255,0,0.35); padding: 0px!important; border-left:1px solid rgba(0,0,0,.2)}

.changer-wrapper .change-row .change-details .row+.row {border: 0px; padding: 0px!important;}
.optimizer-container-report .changer-wrapper .change-row:not(.change-row-header) .change-details .row .col-6:nth-child(2n+1) {padding-top: 0.13rem!important; padding-bottom: 0.13rem!important;}

/*.optimizer-container-report .changer-wrapper .change-row:first-child .change-details .row .col-6:nth-child(2) , .optimizer-container-report .changer-wrapper .change-row .change-details .row:nth-child(2) .col-6:nth-child(2) {max-width:300px;}*/

.optimizer-container-report .changer-wrapper .change-row-header .col-8 > .row, .optimizer-container-report .changer-wrapper .change-row:first-child .col-4 > .row {padding:0!important;}

.optimizer-container-report .changer-wrapper .change-row .change-details {flex: 0 0 67%; max-width: 67%; }
.optimizer-container-report .changer-wrapper .change-row .savings-details, .optimizer-container-report .changer-wrapper .change-row:first-child .col-4 {flex: 0 0 33%; max-width: 33%;}

.optimizer-container-report .changer-wrapper .change-row .change-details .row .col-6:nth-child(2n+1), .optimizer-container-report .changer-wrapper .change-row:first-child .change-details .row .col-6 .col-6:nth-child(2) {flex: 0 0 50%; max-width: 50%;}
.optimizer-container-report .changer-wrapper .change-row .change-details .row .col-6:nth-child(2n), .optimizer-container-report .changer-wrapper .change-row:first-child .change-details .row .col-6 .col-6:nth-child(3) {flex: 0 0 50%; max-width: 50%;padding-left: 3px;}

.optimizer-container-report .changer-wrapper .change-row .change-details .change-row-item > .col-6:first-child, .optimizer-container-report .changer-wrapper .change-row .change-details .row .config-header.col-6 {flex: 0 0 60%; max-width: 60%;}
.optimizer-container-report .changer-wrapper .change-row .change-details .change-row-item > .col-6:nth-child(2), .optimizer-container-report .changer-wrapper .change-row .change-details .row .investments-cost.col-6 {flex: 0 0 40%; max-width: 40%;}

.optimizer-container-report .changer-wrapper .change-row-header .change-details .row .col-6 .col-6:nth-child(2), .optimizer-container-report .changer-wrapper .change-row:first-child .change-details .row .col-6:first-child .col-12:first-child {padding-left:0;}

.optimizer-container-report .changer-wrapper .change-row-header .change-details .row .col-6:first-child p {text-align: left!important;}

.change-row-item-heatingSource .col-6 h3{padding-top:5px; padding-bottom:5px;}

.optimizer-container-report .irr-form p {line-height:100%;}

.optimizer-container-report .irr-form .house-numeric-input .react-numeric-input #irr {min-width: 75px; text-align:right; max-width: 75px;}

.changer-wrapper .change-row .savings-details {padding-top: 2px; padding-bottom:4px;}

.thermoderm {padding-bottom: 10px;}

.results-summary p {line-height: 110%;}


.optimizer-container-report .change-details {max-width: 75%; flex: 0 0 75%;}
.optimizer-container-report .savings-details {max-width: 25%;}
.optimizer-container-report .house-popup-static .card-body {padding-bottom: 0px;}
.optimizer-container-report .change-details p.mb-0, .optimizer-container-report .cost-details p.mb-0 {margin-bottom: 0.25rem!important; line-height: 100%;}
/*.optimizer-container-report .change-details div.obsolescence-details::after {content: " lat";}*/

.thermoderm-optimize {text-align: right; height: 28px;}

.cost-details .work-cost, .cost-details .material-cost, .cost-details .total-cost, .savings-details .savings-energy, .savings-details .savings-energy-cost, .savings-rates .savings-irr {font-size: 1.1rem; font-weight: 400;}
.cost-details .total-cost, .savings-details .savings-energy-cost, .savings-rates .savings-irr {font-weight: 600;}

.custom-accordion .accordion .baloon.personal-gain-baloon {display:none!important;}

.change-row-item-foundationType div:first-child div:nth-child(3) p.mb-0, .change-row-item-foundationType div:first-child div:nth-child(4) p.mb-0 {display:none!important;}

.change-row-item-pvType .fa-info-circle {margin-left:-20px!important;}


/*edycja kosztow*/
.card-body .thermoderm.row .col-6 p {line-height: 110%; margin-bottom: 0;}

.card-body .thermoderm.row .thermoderm-row:nth-child(2) div.col-6.pr-1 > p:first-child,
.card-body .thermoderm.row .thermoderm-row:nth-child(3) div.col-6.pr-1 > p:first-child,
.card-body .thermoderm.row .thermoderm-row-column div.col-12 > p:first-child {overflow:hidden; max-height: 10px;}

/*****buttony******/

.thermoderm-optimize .btn {margin-right: 3px; right: 0px; margin-bottom: 5px; margin-top:3px; font-size: 0.87rem; padding: 0.05rem 0.4rem; min-width: 80px;}

.change-row-item-shutters .btn-primary {display:none!important;}
.optimizer-container-report .changer-wrapper .change-row:not(.change-row-header) .change-details .row.change-row-item-shutters div:first-child p.mb-0:first-child { flex: 0 0 100%; max-width: 100%;}
.change-row-item-shutters div div:nth-child(2) {display:none;}

/*****podsumowanie raportu******/
.results-summary {font-size: 1.1rem; height: 100px; padding: 5px 15px!important;}
.results-summary .house-numeric-input {padding-left:0px;}
.results-summary .house-numeric-input .text-medium {line-height: 110%!important;}
.results-summary .sum-total {text-align:right;}
.results-summary .totalSavings, .results-summary .totalSavingsPrice {text-align:center; padding-right: 5px; line-height: 110%!important;}
.results-summary .totalSavings { padding-left: 5px; }
.results-summary .totalSavingsPrice {padding-left:5px;}
.results-summary .totalSavingsIrr {padding-left: 20px; text-align: center;}

/*****wykresy******/
.energy-cost {
	font-size: 1.3rem;
	font-weight: bold;
	top: 0px;
	text-align:left!important;
}
.energy-cost h4 {font-size: 1rem; font-family: Roboto, Arial, Tahoma, sans-serif; color:#333c45; margin-bottom: 2px; font-weight: 400;}

.energy-class {margin-left: -13px;}
.energy-class-value {text-align:left; margin-right: 40px; font-size: 1.3rem;
	line-height: 100%; padding-right: 20px; padding-top: 6px; text-transform: uppercase; font-weight: bold;}
#energyClassCodeLabel {right:0px;}

#plotContainer > .d-flex > .col-7 > .row > .col-5.p-0, #plotContainer > .d-flex > .col-7 > .row > .col-8.p-0 {max-width: 220px;  flex: 0 0 30.66667%;}
#plotContainer > .d-flex > .col-7 > .row > .col-3:nth-child(3) {max-width: 130px;    font-weight: bold; padding-right: 40px!important; padding-top: 2px;}
#plotContainer > .d-flex > .col-7 > .row > .col-3:nth-child(4) .text-left {width:50px;}
#plotContainer > .d-flex > .col-7 > .row > .col-3:nth-child(4) .text-right {width:75px; font-weight:bold; padding-top: 2px!important;}

#plotContainer .pie-chart-groups {bottom: 65px;}

.recharts-wrapper .recharts-surface .xAxis g.recharts-cartesian-axis-ticks .recharts-layer.recharts-cartesian-axis-tick g text:nth-child(2) {display:none;}

/*charakterystyka-budynku*/

.house-specs {max-width:800px; margin: 0 auto;}
.house-specs table {border: 0px;}
.house-specs table td {border: 1px #dedede solid; border-collapse: collapse;}
.house-specs table th {border: 0px;}
.house-specs table thead th h2 {font-size: 18px; margin-bottom: 10px!important;}
.house-specs table td:first-child {text-align:left;}
.house-specs table td .energy-class-value {text-align: center; padding-right: 0px; margin: 0px;}
.house-specs table td:nth-child(2), .house-specs table td:nth-child(3), .house-specs table td:nth-child(4), .house-specs table td:nth-child(5) {width: 140px;}

/*wyostrzanie czcionek i obrazkĂłw*/

.house-types .house-type.col-6 {font-size: 12px; max-width: 48%;}
.house-types .house-type.col-4 {font-size: 12px; width: 33%; max-width: 164px; flex: 0 0 33%;}
.house-types .house-type span img {max-width:100px;}
.modal-dialog {max-width: 492px;}
.house-select img.house-icon {width: 22px; margin-top: -2px;}
#wallHoleRecInImg, #wallHoleImg {height:26px!important;}

/****nowe po przesuwaniu maj2020****/

.heatPopupMainGroup div.col-6:nth-child(11) {margin-right: 65px;}
.heatPopupMainGroup div.col-4:nth-child(16) {font-size:100%!important;line-height:100%;}
.heatPopupMainGroup .warmWaterBorderContainer {padding-top: 15px; border-top: 1px solid rgba(0,0,0,.1);}
.heatPopupMainGroup .warmWaterBorderContainer hr.col-12 {margin-top:0.5rem;}


/***********************************/
/****do wysokich ekranĂłw desktopa*****/
/***********************************/

@media screen and (min-height: 750px) {
	#HeatingPopup2 .card-body.with-details, #HousePopup2 .card-body.with-details {
		height: 630px;
	}

	#HeatingPopup2.firstCalculationStep2 .card-body.with-details, #HeatingPopup2 .card-body.with-details, #HousePopup2 .card-body.with-details {
		height: 605px !important;
	}

	.housePopupUpperGroup div.col-8, .personal-gain-input > div, .housePopupUpperGroup div.col-4.house-numeric-input:nth-child(14) {
		padding-left: 0px !important;
	}

	/***********************************/
	/****do duĹźych ekranĂłw desktopa*****/
	/***********************************/
}
	@media only screen and (min-width:1650px) {

		#content_container.upFloor {
			background-size: auto 977px!important;}

		.no-accordion-styles .baloon, .hidePanels .baloon.source-loss-baloon {width:246px;}
		.no-accordion-styles .baloon.personal-gain-baloon, .no-accordion-styles .baloon.dropdown-baloon-water {width: 130px;}
		.no-accordion-styles .baloon .popover-body {width:100%;}
		.foundations .form-control {min-width: 164px;}
		.doors .form-control {min-width: 164px;}
		#HeatingPopup2.firstCalculationStep2 .card-body.with-details {height: 652px;}

		.baloon.dropdown-baloon-air-conditioning .card-body > div:nth-child(2) p {font-size:0.9rem;}

		/*panele lewe*/

		.custom-accordion .popup .custom-icon {margin-left:-3px;}
		.custom-accordion .card.photovoltaic-popup {border-top: 1px solid rgba(0,0,0,.2);}

		.custom-accordion .baloon .custom-icon {font-size: 24px; height: 24px; min-width: 24px; margin-right: .7rem!important;}
		.custom-accordion .baloon.personal-gain-baloon .custom-icon {margin-left: 0px;}
		.custom-accordion .card-body {padding: 1rem 1.25rem .75rem; min-height: 75px;}

		.accordion .popup .card-header .custom-icon {font-size: 30px; height: 30px; min-width: 30px; margin-right: .7rem!important;}

		/*ikonki*/

		.insolation-baloon.baloon.bs-popover-left .baloon-icon {right: 162px; top: auto; bottom: -125px;}
		.dropdown-baloon-airing.baloon.bs-popover-left .baloon-icon {right: 162px; top: 166px;}
		.window-baloon.baloon.bs-popover-left .baloon-icon {right: 162px; top:190px;}
		.ventilation-baloon.baloon.bs-popover-left .baloon-icon {right: 162px; top: 273px;}

		.collectors-icon.baloon .baloon-icon {right: 355px; top: 55px;}
		.photovoltaic-icon.baloon .baloon-icon {right:130px; top: 55px;}
		.personal-gain-baloon.baloon .baloon-icon {left: -40px; top: 153px;}
		.ventilation_electric_system-baloon.baloon.bs-popover-right .baloon-icon {left: 345px; top: 287px;}
		.dropdown-baloon-water.baloon .baloon-icon {right: -45px; top: 238px;}
		.living_needs-baloon.baloon .baloon-icon {top: -155px; right: -160px;}
		.heat_electric_system-baloon.baloon .baloon-icon {top: 217px; left: -213px;}

		.insulation-baloon-roof.baloon .baloon-icon {left: 44px; top: 175px; }
		.insulation-baloon-wall.baloon.bs-popover-right .baloon-icon {left: 162px; top: 184px;}
		.baloon.dropdown-baloon-air-conditioning .baloon-icon {top: 202px; left:182px;}
		.baloon.source-loss-baloon .baloon-icon {left: 82px; top: 340px;} /*nietrzebazmieniac left*/
		.insulation-baloon-floor.baloon.bs-popover-right .baloon-icon {left: -575px; top: 321px;}
		.basement-baloon.baloon .baloon-icon {left: -685px; top: 700px;}

		/*dymki*/


		.hidePanels .baloon.insolation-baloon, .hidePanels .baloon.dropdown-baloon-airing, .hidePanels .baloon.window-baloon, .hidePanels .baloon.ventilation-baloon {left:-290px;}

		.photovoltaic-popup #heatPopupMainGroup div.col-4:first-child > p, .photovoltaic-popup #heatPopupMainGroup div.col-4:nth-child(2) > p  {padding-top:5px;}

		.hidePanels .baloon.insolation-baloon {top:auto; bottom: 565px;}
		.hidePanels .baloon.dropdown-baloon-airing {top:393px;}
		.hidePanels .baloon.window-baloon {top:462px;}
		.hidePanels .baloon.ventilation-baloon {top:602px;}
		.hidePanels .baloon.ventilation-baloon.baloon.bs-popover-left .arrow {top: auto;}

		.hidePanels .baloon.personal-gain-baloon {left: 330px;top: 375px;}
		.hidePanels .baloon.ventilation_electric_system-baloon {top: 584px; left: 225px; width: 130px;}
		.hidePanels .baloon.dropdown-baloon-water { left: 555px;  top: 450px;}
		.hidePanels .baloon.living_needs-baloon {top: 320px; left: 555px; width: 130px;}

		.hidePanels .baloon.insulation-baloon-roof, .hidePanels .baloon.insulation-baloon-wall, .hidePanels .baloon.dropdown-baloon-air-conditioning, .hidePanels .baloon.source-loss-baloon {right:-290px;}

		.hidePanels .baloon.insulation-baloon-roof {top:156px}
		.hidePanels .baloon.insulation-baloon-wall {top: 267px;}
		.hidePanels .baloon.insulation-baloon-wall.baloon.bs-popover-top-right .arrow {bottom: 43%;}
		.hidePanels .baloon.dropdown-baloon-air-conditioning {top: 437px;}
		.hidePanels .baloon.heat_electric_system-baloon {top: 600px; right: -290px; left: auto; width:240px;}
		.hidePanels .baloon.source-loss-baloon {top: 658px;}
		.hidePanels .baloon.baloon.basement-baloon {top:642px; right: 488px;}
		.hidePanels .baloon.insulation-baloon-floor {top:675px; right: 355px;}

		/*strzalki*/

		.arrows > div > .label-value {width: 100%;}

		.arrows.sun-arrow.gain {top:306px; left:15px;}
		.arrows.airing-arrow {top:378px; left:-36px;}
		.arrows.windows-arrow {top:460px; left:-36px;}
		.arrows.ventilation-arrow {top:633px; left:-36px;}
		.arrows.ventilation-systems-arrow {top: 560px; left: 160px;}

		.arrows.person-arrow.gain {top: 320px; left:260px;}
		.arrows.warm-water-arrow.loss {top: 455px; left: 670px;}
		.arrows.collector-arrow {top: 155px; left: 330px;}
		.arrows.photovoltaic-arrow {top: 154px; left: 595px;}
		.arrows.living-needs-arrow {top: 245px;left: 670px;}
		.arrows.heating-systems-arrow {
			top: 506px;
			right: -56px;
		}

		.arrows.roof-arrow {top: 102px; right: 54px;}
		.arrows.wall-arrow {top: 275px;right:-36px;}
		.arrows.air-conditioning-arrow {top: 420px;left: auto;right: -36px;}
		.arrows.heat-loss-arrow.gain {top: 633px;right:15px;}
		.arrows.heat-loss-arrow.loss {top: 633px;right:-36px;}
		.arrows.floor-arrow {top: 682px; left: 325px;}
		.arrows.basement-arrow {top: 682px; left: auto; right: 658px;}

		/*inne*/
		#content_container {background-size:auto 985px!important;}

		.baloon.insulation-baloon .card-body .row:not(.thermoderm) .col-12 {max-width:143px;}
		.baloon.insulation-baloon .card-body .row:not(.thermoderm) .col-12.u0 {max-width:200px; padding-bottom: 2px;}
		.baloon.insulation-baloon .card-body .row:not(.thermoderm) .col-12.u0 p.pr-2 {max-width:110px;}
		.hidePanels .baloon.insulation-baloon .card-body .fa-info-circle {right: -5px;}
		.custom-accordion .baloon.insulation-baloon .card-body .fa-info-circle {right: -110px;}
		.custom-accordion .baloon.insulation-baloon .card-body .row:not(.thermoderm) .col-12 {max-width:190px;}

		#shuttersWithoutBridgeImg {

			top: 293px!important;
			width: 80px!important;
			height: auto!important;
		}

		#bridgeDownInsulationImg {
			top: 554px;
		}
		.heating-device {right: 48px; bottom: 233px; left: 773px; z-index:2;}
		.heating-device#heat_pump-air_water-electric {right: 11px;}
		.heating-device#combined_electric_heat, .heating-device#combined_heat, .heating-device#heat_pump { bottom: 230px; right: 96px;}
		img#electricity-radiator-electric {left: 713px;}
		img#electricity-accumulating-electric {left: 703px;}
		img.solid_fuel_boiler {left: 768px;}
		img.no_water_jacket, img.water_jacket {left: 720px;}
		img#heating-device-pipe1 {left: 840px;}

		img#heat_pump-air_air-electric {top:340px;}
		img#heating-device-air_air {top:385px;}

		#wallDownLeftInsulationImg { top: 565px;}

		#roofGroup {top: 53px} /*taka niech bÄdzie tutaj wartoĹÄ startowa - bo wczeĹniej byĹa dziura i przeĹwitywaĹo niebo pomiÄdzy dachem a izolacjÄ lub domem*/


		#recuperatorImg {bottom: 220px;}

		.hidePanels .baloon.ventilation-baloon.baloon.bs-popover-left p.mb-0 .fa-info-circle {
			margin-left: 10% !important;}

		.results-summary {font-size: 1.5rem; max-height: 97px; padding: 5px 15px!important; height:97px;}
		.results-summary b {font-size: 1rem;}

		.results-summary div.col-3 div.d-flex p.text-medium:nth-child(2) {
			font-size:1rem;
			font-weight:400;
			margin-left: 52px;
			padding: 4px;
			border: 1px solid rgb(204, 204, 204);
			border-radius: 2px;
			min-width: 100px;}
		.results-summary div.col-3 div.d-flex:nth-child(2) p.text-medium:nth-child(2) {margin-left: 89px;}
		.results-summary div.col-3 div.d-flex p.text-medium:nth-child(1) {padding-top: 5px;}
		.results-summary div.col-3 div.d-flex.house-numeric-input p.text-medium:nth-child(1) {padding-top: 0px;}
		.results-summary .house-numeric-input .react-numeric-input {max-width: 100px; }
		.results-summary p.text-small {font-size: 1rem!important; padding-bottom:0px;}


	}

	/***********************************/
	/*do laptopowych i trochÄ wiÄkszych*/
	/***********************************/

	@media (min-width:1280px) and (max-width:1649px) {
		#content_container.skyType.downFloor {
			background-size: auto 680px !important;
		}
		/*panele lewe*/

		.custom-accordion .popup .custom-icon {margin-left:-3px;}
		.custom-accordion .card.photovoltaic-popup {border-top: 1px solid rgba(0,0,0,.2);}

		.custom-accordion .custom-icon {font-size: 20px; height: 20px; min-width: 20px; margin-right: .7rem!important;}
		.custom-accordion .baloon.personal-gain-baloon .custom-icon {margin-left: 0px;}
		.custom-accordion .card-body {padding: 1rem 1.25rem .75rem; min-height: 75px;}

		.accordion .popup .card-header .custom-icon {font-size: 24px; height: 24px; min-width: 24px; margin-right: .7rem!important;}

		/*ikonki*/

		.insolation-baloon.baloon.bs-popover-left .baloon-icon {bottom: -45px; top: auto;}
		.dropdown-baloon-airing.baloon.bs-popover-left .baloon-icon {top:60px;}
		.window-baloon.baloon.bs-popover-left .baloon-icon {top:53px;}
		.ventilation-baloon.baloon.bs-popover-left .baloon-icon {top: 62px;}

		.collectors-icon.baloon .baloon-icon {top: -35px; left: -180px;}
		.photovoltaic-icon.baloon .baloon-icon {top: -35px; left: -30px;}
		.personal-gain-baloon.baloon .baloon-icon {left: -5px; top: -10px;}
		.ventilation_electric_system-baloon.baloon.bs-popover-right .baloon-icon {left: 442px; top: 32px;}
		.dropdown-baloon-water.baloon .baloon-icon {right: 69px; top: 86px;}
		.living_needs-baloon.baloon .baloon-icon {top: -244px; left: 255px;}
		.baloon.heat_electric_system-baloon {top: 375px; right: 90px;}

		.insulation-baloon-roof.baloon.bs-popover-right .baloon-icon {left: -158px;top: 112px;}
		.insulation-baloon-wall.baloon.bs-popover-right .baloon-icon {left: -45px;top: 63px;}
		.dropdown-baloon-air-conditioning.baloon.bs-popover-right .baloon-icon {left: -25px; top: 31px;}

		.insulation-baloon-floor.baloon.bs-popover-right .baloon-icon {left: -500px; top: 50px;}
		.basement-baloon.baloon .baloon-icon {left: -630px; top: 447px;}

		/*strzalki*/

		.arrows.sun-arrow {top:225px}
		.arrows.airing-arrow {top:272px;}
		.arrows.windows-arrow {top:325px}
		.arrows.ventilation-arrow {top:422px}
		.arrows.ventilation-systems-arrow {top: 305px; left: 263px;}

		.arrows.collector-arrow {left:356px}
		.arrows.photovoltaic-arrow {left:555px}
		.arrows.person-arrow {top: 158px;left: 300px;}
		.arrows.living-needs-arrow {top: 157px; left: 611px;}
		.arrows.warm-water-arrow {top: 305px; left: 566px;}
		.arrows.heating-systems-arrow {
			top: 234px;
			right: 91px;
		}

		.arrows.roof-arrow {top:37px; left:70%;}
		.arrows.wall-arrow {top: 154px;}
		.arrows.air-conditioning-arrow {top: 249px;}

		.arrows.floor-arrow {top: 411px; left: 456px;}
		.arrows.basement-arrow {top: 411px;left: auto;right: 610px;}


		/*dymki i ich elementy*/

		.hidePanels .baloon.insolation-baloon .popover-body > div.content .card-body .row p.col-12 {display:none!important;}
		.hidePanels .baloon.insolation-baloon {bottom:645px;top:auto;}
		.hidePanels .baloon.insolation-baloon .popover-body {padding-bottom:0px;}
		.hidePanels .baloon.insolation-baloon.bs-popover-left .arrow {}
		.hidePanels .baloon.dropdown-baloon-airing {top:295px;}
		.hidePanels .baloon.airing-baloon .popover-body {padding-bottom:1px;}
		.hidePanels .baloon.airing-baloon .arrow {top:14px;}
		.hidePanels .baloon.window-baloon {top:348px}
		.hidePanels .baloon.window-baloon .popover-body {padding-bottom:1px;}
		.hidePanels .baloon.window-baloon .arrow {top:15px}
		.hidePanels .baloon.ventilation-baloon {top:425px;}
		.hidePanels .baloon.ventilation-baloon .popover-body {padding-bottom:0px; margin-bottom:-5px;}
		.hidePanels .baloon.ventilation-baloon.baloon.bs-popover-left .arrow {top:35px;}

		.hidePanels .baloon.personal-gain-baloon {top:215px;left: 362px;}
		.hidePanels .baloon.ventilation_electric_system-baloon {top: 330px; left: 327px; width: 115px;}
		.hidePanels .baloon.living_needs-baloon {top: 232px; left: 510px; width: 120px;}
		.hidePanels .baloon.dropdown-baloon-water { left: 452px; top: 297px;max-width: 130px;}


		.hidePanels .baloon.insulation-baloon .card-body .fa-info-circle {right: 72px;}
		.hidePanels .baloon.insulation-baloon-roof {top:35px;}
		.hidePanels .baloon.insulation-baloon-wall {top: 137px;}
		.hidePanels .baloon.insulation-baloon-wall .arrow {bottom:auto}
		.hidePanels .baloon.dropdown-baloon-air-conditioning {top: 267px;}
		.hidePanels .baloon.source-loss-baloon {top: 328px;}
		.hidePanels .baloon.source-loss-baloon .popover-body h3.col-10 {flex: 0 0 87.33333%; max-width:87.33333%}
		.hidePanels .baloon.insulation-baloon-floor {top:421px;}
		.hidePanels .baloon.baloon.basement-baloon {top: 391px; right: 440px;}

		/*inne*/
		#content_container {background-size:auto 666px!important;}

		#shuttersWithoutBridgeImg {

			top: 293px!important;
			height: auto!important;
			width: 85px!important;
		}

		#bridgeDownInsulationImg {
			top: 554px;
			/*right: 934px;*/
		}
		#recuperatorImg {bottom: 219px;}
		img#heat_pump-air_air-electric {top:310px;}
		/*.heating-device#heat_pump-air_water-electric {right: 11px;}*/
		img#heating-device-air_air {top:350px;}
		#floorInsulationImg {width: 914px!important;
			/*left: 43px;*/
		}

		/*#wallDownLeftInsulationImg { right: 956px;}*/

		/*poprawki bo sie wszystko rozsypalo 21.09.2019*/

		/*#wallDownLeftInsulationImg { left: auto;}*/

		.result-summary {height: 41px;}
	}

	/***********************************/
	/****do tabletĂłw i trochÄ wiÄkszych*/
	/***********************************/

	@media (min-width:1280px) and (max-width:1365px) {
		#content_container.skyType.downFloor {
			background-size: auto 680px !important;
		}
		.custom-accordion .row {margin-bottom:5px; margin-top:5px;}


		.housePanel-wrapper {
			width: calc(100% - 310px);
			margin: 0 0 0 auto;
			max-width: 100%;
		}

		.housePanel-container {
			width: 1000px;
			max-width: 100%;
			margin: 0 auto;
		}

		.house-popup .walls span.react-numeric-input {max-width:62px!important;min-width:62px!important;}
		.house-popup input.short-input {
			 min-width: 55px; 
			max-width: 70px;
			max-height: 30px;
		}
	}

	/***********************************/
	/****do tabletĂłw i trochÄ wiÄkszych*/
	/***********************************/

	@media (min-width:1024px) and (max-width:1279px) {

		#content_container.skyType.downFloor {
			background-size: auto 680px !important;
		}

		.custom-accordion .row {
			margin-bottom: 5px;
			margin-top: 5px;
		}

		p.showPopups.mb-0.mx-1.d-flex {
			display: none !important;
		}

		.energy-meter {
			left: 46%
		}

		.housePanel-wrapper {
			width: calc(100% - 310px);
			margin: 0 0 0 233px;
			max-width: 100%;
		}

		.housePanel-container {
			width: 1000px;
			max-width: 100%;
			margin: 0 auto;
		}

		/*panele lewe*/
		.custom-accordion .popup .custom-icon {
			margin-left: -3px;
		}

		.custom-accordion .card.photovoltaic-popup {
			border-top: 1px solid rgba(0, 0, 0, .2);
		}

		.custom-accordion .baloon .custom-icon {
			font-size: 24px;
			height: 24px;
			min-width: 24px;
			margin-right: .7rem !important;
		}

		.custom-accordion .baloon.personal-gain-baloon .custom-icon {
			margin-left: 0px;
		}

		.custom-accordion .card-body {
			padding: 1rem 1.25rem .75rem;
			min-height: 75px;
		}

		.accordion .popup .card-header .custom-icon {
			font-size: 30px;
			height: 30px;
			min-width: 30px;
			margin-right: .7rem !important;
		}

		.ml-2, .mx-2 {
			margin-left: .1rem !important;
		}

		.react-numeric-input, .form-control, .house-select, .region-select, input[type=password], input[type=text], select, textarea {
			min-width: 70px;
		}

		.house-popup input.short-input {
			min-width: 55px;
			max-width: 70px;
			max-height: 30px;
		}

		.house-popup span.react-numeric-input {
			min-width: 55px;
			max-width: 74px !important;
		}

		.house-popup .card-body .house-numeric-input {
			min-width: 55px;
			max-width: 74px;
		}

		.house-popup .card-body .house-numeric-input:nth-child(11) {
			max-width: 85px;
		}

		.house-popup .card-body .house-numeric-input:nth-child(12) {
			padding-top: 10px;
			padding-left: 4px;
		}

		.baloon.insulation-baloon .card-body .row:not(.thermoderm) .col-12 {
			max-width: 135px;
		}

		.baloon.insulation-baloon .card-body .row:not(.thermoderm) .col-6.pr-1 {
			max-width: 80px;
		}

		.baloon.insulation-baloon .card-body .row:not(.thermoderm) .col-6.pl-1 {
			max-width: 85px;
		}

		/*#roofGroup {left: -37px;}*/
		#flatRoofImg {
			left: -30px;
		}

		#shuttersWithoutBridgeImg {
			left: 0px;
		}

		/*.heating-device {right: -180px;}*/
		.heating-device#heat_pump, .heating-device#combined_electric_heat, .heating-device#combined_heat {
			left: 778px;
			bottom: 230px;
		}

		/*img#heating-device-pipe1 {right:-230px;}*/
		/*img#heating-device-pipe2 {right: -327px;}*/
		/*img#heating-device-monoblok {left: 914px;}*/
		img#heat_pump-air_air-electric {
			/*right: -170px;*/
			top: 320px;
		}

		img#heating-device-air_air {
			/*right: -290px;*/
			top: 360px;
		}

		/*img#heat_pump-air_water-electric {right: -285px;}*/
		/*img#heating-device-glycol_water { right: -170px;}*/
		/*#wallRightInsulationImg {left:914px;}*/
		#wallDownLeftInsulationImg {
			top: 565px;
		}

		#wallUpLeftInsulationImg {
			top: 211px;
		}

		/*#floorInsulationImg,#roofInsulationImg {left: 0px;}*/
		#bridgeUpInsulationImg {
			/*left:-24px; */
			top: 292px;
		}

		#bridgeDownInsulationImg {
			/*left:-24px; */
			top: 554px;
		}

		#leftFoundationImg {
			bottom: 143px;
			/*left: -14px;*/
		}

		#rightFoundationImg {
			bottom: 143px;
			/*right: -244px;*/
		}

		#wallHoleImg, #wallHoleRecInImg, #wallHoleRecOutImg {
			left: -24px;
		}

		#recuperatorImg {
			bottom: 219px;
			left: 33px;
		}

		#groundHeatExchangerImg {
			left: -65px;
		}

		#openWindowImg {
			left: 31px;
		}

		#shuttersWithoutBridgeImg {
			left: 0 !important;
			top: 285px !important;
			width: 80px;
		}

		#bathImage {
			right: 20px;
		}

		/*strzalki*/
		.arrows.sun-arrow {
			top: 175px;
			left: 106px;
		}

		.arrows.airing-arrow {
			top: 235px;
			left: 55px;
		}

		.arrows.windows-arrow {
			top: 298px;
			left: 56px;
		}

		.arrows.ventilation-arrow {
			top: 375px;
			left: 55px;
		}

		.arrows.collector-arrow {
			top: 25px;
			left: 260px;
		}

		.arrows.photovoltaic-arrow {
			top: 25px;
			left: 490px;
		}

		.arrows.ventilation-systems-arrow {
			top: 300px;
			left: 200px;
		}

		.arrows.heat-loss-arrow {
			top: 305px;
			right: -93px;
		}

		.arrows.heat-loss-arrow.gain {
			right: -97px;
		}

		.arrows.living-needs-arrow {
			top: 153px;
			left: 372px;
		}

		.arrows.warm-water-arrow {
			top: 244px;
			left: 455px;
		}

		.arrows.heating-systems-arrow {
			top: 300px;
			right: 35px;
		}

		.arrows.roof-arrow {
			top: 27px;
			right: -29px;
		}

		.arrows.wall-arrow {
			top: 145px;
			right: -93px;
		}

		.arrows.air-conditioning-arrow {
			top: 237px;
			right: -93px;
		}

		.arrows.floor-arrow, .arrows.basement-arrow {
			top: 427px;
			left: 300px;
		}

		/*ikony*/
		.insolation-baloon.baloon.bs-popover-left .baloon-icon {
			right: 58px;
		}

		.dropdown-baloon-airing.baloon.bs-popover-left .baloon-icon {
			top: 24px;
			right: -154px;
		}

		.window-baloon.baloon.bs-popover-left .baloon-icon {
			top: 25px;
		}

		.ventilation-baloon.baloon.bs-popover-left .baloon-icon {
			top: 15px;
		}

		.collectors-icon.baloon .baloon-icon {
			top: -70px;
			left: -275px;
		}

		.photovoltaic-icon.baloon .baloon-icon {
			top: -70px;
			left: -95px;
		}

		.personal-gain-baloon.baloon .baloon-icon {
			left: -75px;
			top: 25px;
		}

		.living_needs-baloon .baloon-icon {
			top: -248px;
			left: 95px;
		}

		.dropdown-baloon-water.baloon .baloon-icon {
			right: 100px;
			top: 25px;
		}

		.baloon.heat_electric_system-baloon .baloon-icon {
			top: -40px;
			left: 60px;
		}

		.insulation-baloon-roof.baloon.bs-popover-right .baloon-icon {
			top: 95px;
		}

		.insulation-baloon-wall.baloon.bs-popover-right .baloon-icon {
			left: 112px;
			top: 53px;
		}

		.dropdown-baloon-air-conditioning.baloon.bs-popover-right .baloon-icon {
			left: 8px;
		}

		.baloon.source-loss-baloon .baloon-icon {
			left: 112px;
			top: 12px;
		}

		.insulation-baloon-floor.baloon.bs-popover-right .baloon-icon {
			left: -135px;
			top: 65px;
		}

		.basement-baloon.baloon .baloon-icon {
			left: -40px;
			top: 445px;
		}

		.walls .row {
			margin-top: 0;
			margin-bottom: 0;
		}

		.optimizer-container .btn {
			padding: .3rem 0.75rem;
			font-size: 0.9rem;
		}

		.ml-2, .mx-2 {
			margin-left: 0rem !important;
		}

		.optimizer-container-report .changer-wrapper > .change-row:first-child p {
			font-weight: 600;
			font-size: 12px;
		}

		.optimizer-container-report .irr-form div .house-numeric-input p {
			line-height: 100%;
		}
		.house-popup .walls span.react-numeric-input {max-width:62px!important;}
	}

	/***********************************/
	/****do tabletĂłw i trochÄ wiÄkszych*/
	/***********************************/

	@media (min-width:1024px) and (max-width:1279px) {
		.energy-meter {
			left:50%;
		}

		.energy-meter > div {
			min-width:680px;
		}

		.energy-meter .btn {

			cursor: default;
		}
		.accordion .row {margin-bottom:5px; margin-top:5px;}

		.ml-2, .mx-2 {margin-left: .1rem!important;}
		.react-numeric-input, .form-control, .house-select, .region-select, input[type=password], input[type=text], select, textarea {min-width:75px;}

		input.short-input {max-width:75px;max-height:30px;}
		span.react-numeric-input {max-width: 75px!important;}
		.house-popup .card-body .house-numeric-input {max-width:140px;}
		.house-popup .card-body .house-numeric-input:nth-child(11) {max-width:108px;}
		.house-popup .card-body .house-numeric-input:nth-child(12) {padding-top: 10px;}

		/*panele lewe*/

		.custom-accordion .popup .custom-icon {margin-left:-3px;}
		.custom-accordion .card.photovoltaic-popup {border-top: 1px solid rgba(0,0,0,.2);}

		.custom-accordion .baloon .custom-icon {font-size: 24px; height: 24px; min-width: 24px; margin-right: .7rem!important;}
		.custom-accordion .baloon.personal-gain-baloon .custom-icon {margin-left: 0px;}
		.custom-accordion .card-body {padding: 1rem 1.25rem .75rem; min-height: 75px;}

		.accordion .popup .card-header .custom-icon {font-size: 30px; height: 30px; min-width: 30px; margin-right: .7rem!important;}

		.house-popup .walls span.react-numeric-input {max-width:62px!important;}


		.optimizer-container .sum-total, .optimizer-container .totalSavings, .optimizer-container .totalSavingsPrice, .optimizer-container .totalSavingsIrr {padding-left: 0px; line-height: 120%;}
		.optimizer-container-report .cost-details {padding-right:2px; padding-left:2px;}
		.optimizer-container-report .change-details {max-width: 69%; flex: 0 0 69%;}
		.optimizer-container-report .savings-details {max-width: 31%;}
		.optimizer-container-report .results-summary {font-size: 1.3rem;}
		.optimizer-container-report .results-summary p .mr-1 {display:block;}

		.optimizer-container-report #housePopupUpperGroup .col-3 .font-weight-bold {line-height:110%; font-weight:normal!important;}
		.optimizer-container-report #housePopupUpperGroup .col-3 .d-inline-block, .optimizer-container-report #housePopupUpperGroup .col-3 .house-select-static, .optimizer-container-report #housePopupUpperGroup .col-3:nth-child(5) .lh-100 {line-height:110%; font-weight:bold; font-size: .9rem;}
		.optimizer-container-report #housePopupUpperGroup {padding-top: 15px;}

		.optimizer-container .btn {padding: .3rem 0.75rem; font-size:0.9rem;}
		.ml-2, .mx-2 {margin-left: 0rem !important;}
	}

	/***********************************/
	/*do smartfonĂłw i trochÄ wiÄkszych**/
	/***********************************/

	@media (max-width:1023px) {

		body::-webkit-scrollbar {  display: none;}
		body::-webkit-scrollbar-button {  display: none;}
		body {-ms-overflow-style:none;}

		.card-body .row {margin-bottom:5px; margin-top:5px;}

		/*ekran startowy*/
		.welcome .modal-body p br {display:none!important;}
		.welcome .btn {min-width: 182px; margin: 5px 10px!important;}

		/*panele lewe*/

		.custom-accordion .popup .custom-icon {margin-left:-3px;}
		.custom-accordion .card.photovoltaic-popup {border-top: 1px solid rgba(0,0,0,.2);}

		.custom-accordion .baloon .custom-icon {font-size: 24px; height: 24px; min-width: 24px; margin-right: .7rem!important;}
		.custom-accordion .baloon.personal-gain-baloon .custom-icon {margin-left: 0px;}
		.custom-accordion .card-body {padding: 1rem 1.25rem .75rem; min-height: 75px;}

		.accordion .popup .card-header .custom-icon {font-size: 30px; height: 30px; min-width: 30px; margin-right: .7rem!important;}


		.react-numeric-input, .form-control, .house-select, .region-select, input[type="password"], input[type="text"], select, textarea {min-width:90px;}

		input.short-input {max-width:100px;max-height:30px;}
		span.react-numeric-input {max-width: 100px!important;}
		.house-popup .card-body .house-numeric-input {max-width:129px;}
		.house-popup .card-body .house-numeric-input:nth-child(11) {max-width:138px;}
		.house-popup .card-body .house-numeric-input:nth-child(12) {padding-top: 10px;}

		.custom-accordion .card-body {min-height: 100px}

		.baloon.insulation-baloon .card-body .row:not(.thermoderm) .col-12 {max-width: 135px;}
		.baloon.insulation-baloon .card-body .row:not(.thermoderm) .col-6.pr-1 {max-width: 80px;}
		.baloon.insulation-baloon .card-body .row:not(.thermoderm) .col-6.pl-1 {max-width: 85px;}


		.house-select, .region-select, input[type=password], input[type=text], select, textarea {height:calc(2em + .4rem)!important; font-size: 1rem; padding: 0.3rem;}

		p.showPopups.mb-0.mx-1.d-flex {display: none !important;}

		.house-popup .walls span.react-numeric-input {max-width:62px!important;}

		/*ukrycie optymalizatora*/
		.balance-report > div > span {display:none;}

	}

	/***do komorek waskich pionowych***/

	@media (max-width:680px) {

		body::-webkit-scrollbar {  display: none;}
		body::-webkit-scrollbar-button {  display: none;}
		body {-ms-overflow-style:none;}

		.card-body .row {margin-bottom:5px; margin-top:5px;}

		/*ekran startowy*/
		.welcome .modal-body p br {display:none!important;}
		.welcome .btn {min-width: 182px; margin: 5px 10px!important;}
		.welcome.modal .modal-dialog {width:310px;}
		.welcome.modal .modal-content .modal-body {padding:1rem;}

		.navbar-brand, img.logo {
			max-width: 142px;
			margin: 0 auto 10px;}

		input.short-input {max-width:75px;max-height:30px;}
		span.react-numeric-input {max-width: 75px!important;}
		
		.baloon.insulation-baloon .card-body .row:not(.thermoderm) .col-12 {max-width: 135px;}
		.baloon.insulation-baloon .card-body .row:not(.thermoderm) .col-6.pr-1 {max-width: 80px;}
		.baloon.insulation-baloon .card-body .row:not(.thermoderm) .col-6.pl-1 {max-width: 85px;}

		.house-popup .card-body .house-numeric-input {
			max-width: none;
		}

		.house-select, .region-select, input[type=password], input[type=text], select, textarea {height:calc(2em + .4rem)!important; font-size: 1rem; padding: 0.3rem;}

		p.showPopups.mb-0.mx-1.d-flex {display: none !important;}

	}

	.house-popup .card-body .house-numeric-input:nth-child(12) {padding-top: 0px!important; padding-left:15px;}
