.recharts-default-tooltip {
  //background:none!important;
  //border:none!important;
  padding: 0.4rem!important;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
}

.pie-chart {
  width:100%;
  height:200px;
  .recharts-wrapper path {
    &:hover {
      transform: scale(1.1);
      transform-origin: center;
    }
  }
  &.circle {
    .recharts-wrapper path {
      stroke: transparent;
    }
  }
}
